html {
  min-height: 100%;
}

body {
  height: 100%;
  font-size: 0.875rem;
  overflow-x: hidden;
  overflow-y: auto;
  letter-spacing: 0.0312rem;
}

:focus {
  outline: none;
}

/* Code for Firefox */
/* ::-moz-selection {
  
  color: #1b55e2;
  background: transparent; } */

/* ::selection {
  color: #1b55e2;
  background: transparent; } */

strong {
  font-weight: 600;
}

code {
  color: #e7515a;
}

select.form-custom::-ms-expand {
  display: none;
}

.custom-file-input:focus ~ .custom-file-label {
  border: 1px solid #ced4da;
  box-shadow: none;
}

.custom-file-input:focus ~ .custom-file-label::after {
  border: none;
  border-left: 1px solid #ced4da;
}

.lead a.btn.btn-primary.btn-lg {
  margin-top: 15px;
  border-radius: 4px;
}

.jumbotron {
  background-color: #f1f2f3;
}

.mark,
mark {
  background-color: #ffeccb;
}

.code-section-container {
  margin-top: 20px;
  text-align: left;
}

.toggle-code-snippet {
  border: none;
  background-color: transparent !important;
  padding: 0px !important;
  box-shadow: none !important;
  color: #888ea8 !important;
  margin-bottom: -24px;
  border-bottom: 1px dashed #bfc9d4;
  border-radius: 0;
}

.toggle-code-snippet svg {
  color: #1b55e2;
}

.code-section {
  padding: 0;
  height: 0;
}

.code-section-container.show-code .code-section {
  margin-top: 20px;
  height: auto;
}

.code-section pre {
  margin-bottom: 0;
  height: 0;
  padding: 0;
  border-radius: 6px;
}

.code-section-container.show-code .code-section pre {
  height: auto;
  padding: 22px;
}

.code-section code {
  color: #fff;
}

/*blockquote*/
blockquote.blockquote {
  color: #0e1726;
  padding: 20px 20px 20px 14px;
  font-size: 0.875rem;
  background-color: #ffffff;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  border: 1px solid #e0e6ed;
  border-left: 2px solid #1b55e2;
  -webkit-box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08),
    0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08),
    0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08),
    0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07);
}

blockquote.blockquote > p {
  margin-bottom: 0;
}

blockquote .small:before,
blockquote footer:before,
blockquote small:before {
  content: "\2014 \00A0";
}

blockquote .small,
blockquote footer,
blockquote small {
  display: block;
  font-size: 80%;
  line-height: 1.42857143;
  color: #777;
}

blockquote.media-object.m-o-border-right {
  border-right: 4px solid #1b55e2;
  border-left: none;
}

blockquote.media-object .media .usr-img img {
  width: 55px;
}

/* Icon List */
.list-icon {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

.list-icon li:not(:last-child) {
  margin-bottom: 15px;
}

.list-icon svg {
  width: 18px;
  height: 18px;
  color: #1b55e2;
  margin-right: 2px;
  vertical-align: sub;
}

.list-icon .list-text {
  font-size: 14px;
  font-weight: 600;
  color: #515365;
  letter-spacing: 1px;
}

a {
  color: black;
  outline: none;
}

a:hover {
  color: #555555;
  text-decoration: none;
}

a:focus {
  outline: none;
  text-decoration: none;
}

button:focus {
  outline: none;
}

textarea {
  outline: none;
}

textarea:focus {
  outline: none;
}

.btn-link:hover {
  text-decoration: none;
}

span.blue {
  color: #1b55e2;
}

span.green {
  color: #8dbf42;
}

span.red {
  color: #e7515a;
}

/*      Form Group Label       */
.form-group label,
label {
  font-size: 15px;
  color: var(--secondary-color);
  letter-spacing: 1px;
}

/*  Disable forms     */
.custom-control-input:disabled ~ .custom-control-label {
  color: #d3d3d3;
}

/*      Form Control       */
.form-control {
  height: auto;
  /* border: 1px solid #bfc9d4; */
  color: #3b3f5c;
  font-size: 15px;
  padding: 8px 10px;
  letter-spacing: 1px;
  height: calc(1.4em + 1.4rem + 2px);
  padding: 0.75rem 0.4rem;
  /* border-radius: 6px !important; */
}

.form-control:focus {
  box-shadow: 0 0 5px 2px rgba(194, 213, 255, 0.619608);
  border-color: var(--primary-color);
  color: #3b3f5c;
}

.form-control::-webkit-input-placeholder,
.form-control::-ms-input-placeholder,
.form-control::-moz-placeholder {
  color: #acb0c3;
  font-size: 15px;
}

.form-control:focus::-webkit-input-placeholder,
.form-control:focus::-ms-input-placeholder,
.form-control:focus::-moz-placeholder {
  color: #d3d3d3;
  font-size: 15px;
}

.form-control.form-control-lg {
  font-size: 19px;
  padding: 11px 20px;
}

.form-control.form-control-sm {
  padding: 7px 16px;
  font-size: 13px;
}

/*      Custom Select       */
.custom-select {
  height: auto;
  border: 1px solid #f1f2f3;
  color: #3b3f5c;
  font-size: 15px;
  padding: 8px 10px;
  letter-spacing: 1px;
  background-color: #f1f2f3;
}

.custom-select.custom-select-lg {
  font-size: 18px;
  padding: 16px 20px;
}

.custom-select.custom-select-sm {
  font-size: 13px;
  padding: 7px 16px;
}

.custom-select:focus {
  box-shadow: none;
  border-color: #1b55e2;
  color: #3b3f5c;
}

/*      Form Control File       */
.form-control-file {
  width: 100%;
  color: var(--primary-color);
}

.form-control-file::-webkit-file-upload-button {
  letter-spacing: 1px;
  padding: 9px 20px;
  text-shadow: none;
  font-size: 12px;
  color: #fff;
  font-weight: normal;
  white-space: normal;
  word-wrap: break-word;
  transition: 0.2s ease-out;
  touch-action: manipulation;
  cursor: pointer;
  background-color: var(--primary-color);
  box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.2);
  will-change: opacity, transform;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  border-radius: 4px;
  border: transparent;
  outline: none;
}

.form-control-file::-ms-file-upload-button {
  letter-spacing: 1px;
  padding: 9px 20px;
  text-shadow: none;
  font-size: 14px;
  color: #fff;
  font-weight: normal;
  white-space: normal;
  word-wrap: break-word;
  transition: 0.2s ease-out;
  touch-action: manipulation;
  cursor: pointer;
  background-color: var(--primary-color);
  box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.2);
  will-change: opacity, transform;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  border-radius: 4px;
  border: transparent;
  outline: none;
}

.form-control-file.form-control-file-rounded::-webkit-file-upload-button {
  -webkit-border-radius: 1.875rem !important;
  -moz-border-radius: 1.875rem !important;
  -ms-border-radius: 1.875rem !important;
  -o-border-radius: 1.875rem !important;
  border-radius: 1.875rem !important;
}

select.form-control.form-custom {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  vertical-align: middle;
  /* background: #fff url(../img/arrow-down.png) no-repeat right 0.75rem center; */
  background-size: 13px 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/*      Form Control Custom File       */
.custom-file {
  height: auto;
}

.custom-file input {
  height: auto;
}

.custom-file-label {
  height: auto;
  border: 1px solid #f1f2f3;
  color: #3b3f5c;
  font-size: 15px;
  padding: 8px 10px;
  letter-spacing: 1px;
  background-color: #f1f2f3;
}

.custom-file-label::after {
  height: auto;
  padding: 8px 12px;
  color: #515365;
}

/*      Input Group      */
.input-group button:hover,
.input-group .btn:hover,
.input-group button:focus,
.input-group .btn:focus {
  transform: none;
}

.input-group .input-group-prepend .input-group-text {
  border: 1px solid #bfc9d4;
  background-color: #f1f2f3;
}

.input-group .input-group-prepend .input-group-text svg {
  color: #888ea8;
}

.input-group:hover .input-group-prepend .input-group-text svg {
  color: #1b55e2;
  fill: rgba(27, 85, 226, 0.239216);
}

.input-group .input-group-append .input-group-text {
  border: 1px solid #bfc9d4;
  background-color: #f1f2f3;
}

.input-group .input-group-append .input-group-text svg {
  color: #888ea8;
}

.input-group:hover .input-group-append .input-group-text svg {
  color: #1b55e2;
  fill: rgba(27, 85, 226, 0.239216);
}

/*      Input Group append       */
/*      Input Group Append       */
/*      Validation Customization      */
.invalid-feedback {
  color: #e7515a;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 1px;
}

.valid-feedback {
  color: #8dbf42;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 1px;
}

.valid-tooltip {
  background-color: #8dbf42;
}

.invalid-tooltip {
  background-color: #e7515a;
}

.custom-select.is-valid,
.form-control.is-valid {
  border-color: #8dbf42;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%238dbf42' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'%3e%3cpolyline points='20 6 9 17 4 12'%3e%3c/polyline%3e%3c/svg%3e");
}

.was-validated .custom-select:valid,
.was-validated .form-control:valid {
  border-color: #8dbf42;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%238dbf42' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'%3e%3cpolyline points='20 6 9 17 4 12'%3e%3c/polyline%3e%3c/svg%3e");
}

.custom-control-input.is-valid ~ .custom-control-label,
.was-validated .custom-control-input:valid ~ .custom-control-label {
  color: #8dbf42;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23e7515a' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-x'%3e%3cline x1='18' y1='6' x2='6' y2='18'%3e%3c/line%3e%3cline x1='6' y1='6' x2='18' y2='18'%3e%3c/line%3e%3c/svg%3e");
}

.custom-control-input.is-invalid ~ .custom-control-label,
.was-validated .custom-control-input:invalid ~ .custom-control-label {
  color: #e7515a;
}

.dropdown-toggle:after,
.dropup .dropdown-toggle::after,
.dropright .dropdown-toggle::after,
.dropleft .dropdown-toggle::before {
  display: none;
}

.dropdown-toggle svg.feather[class*="feather-chevron-"] {
  width: 15px;
  height: 15px;
  vertical-align: middle;
}

/* .btn {
  padding: 0.4375rem 1.25rem;
  text-shadow: none;
  font-size: 14px;
  color: #3b3f5c;
  font-weight: normal;
  white-space: normal;
  word-wrap: break-word;
  transition: .2s ease-out;
  touch-action: manipulation;
  cursor: pointer;
  background-color: #f1f2f3;
  box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.1);
  will-change: opacity, transform;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out; }
  .btn svg {
    width: 20px;
    height: 20px;
    vertical-align: bottom; }
  .btn.rounded-circle {
    height: 40px;
    width: 40px;
    padding: 8px 8px; }
  .btn:hover, .btn:focus {
    color: #3b3f5c;
    background-color: #f1f2f3;
    border-color: #d3d3d3;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 5px 0 #e0e6ed,0 2px 10px 0 #e0e6ed;
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px); } */

.btn-group .btn:hover,
.btn-group .btn:focus {
  -webkit-transform: none;
  transform: none;
}

.btn.disabled,
.btn.btn[disabled] {
  background-color: #f1f2f3;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.13);
}

.btn.disabled:hover,
.btn.btn[disabled]:hover {
  cursor: not-allowed;
}

.btn .caret {
  border-top-color: #0e1726;
  margin-top: 0;
  margin-left: 3px;
  vertical-align: middle;
}

.btn + .caret,
.btn + .dropdown-toggle .caret {
  margin-left: 0;
}

.btn-group > .btn,
.btn-group .btn {
  padding: 8px 14px;
}

.btn-group-lg > .btn,
.btn-group-lg .btn {
  font-size: 1.125rem;
}

.btn-group-lg > .btn {
  padding: 0.625rem 1.5rem;
  font-size: 16px;
}

.btn-lg {
  padding: 0.625rem 1.5rem;
  font-size: 16px;
}

.btn-group > .btn.btn-lg,
.btn-group .btn.btn-lg {
  padding: 0.625rem 1.5rem;
  font-size: 16px;
}

.btn-group-lg > .btn,
.btn-group-lg .btn {
  font-size: 1.125rem;
}

.btn-group-sm > .btn,
.btn-sm {
  font-size: 0.6875rem;
}

.btn-group > .btn.btn-sm,
.btn-group .btn.btn-sm {
  font-size: 0.6875rem;
}

.btn-group .dropdown-menu {
  border: 1px solid #e0e6ed;
  box-shadow: rgba(113, 106, 202, 0.2) 0px 0px 15px 1px;
  padding: 10px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
}

.btn-group .dropdown-menu a.dropdown-item {
  border-radius: 5px;
  font-size: 12px;
  font-weight: 700;
  color: #888ea8;
  padding: 11px 8px;
}

.btn-group .dropdown-menu a.dropdown-item:hover {
  background-color: #f1f2f3;
}

.btn-group .dropdown-menu a.dropdown-item svg {
  cursor: pointer;
  color: #888ea8;
  margin-right: 6px;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  fill: rgba(0, 23, 55, 0.08);
}

.btn-group .dropdown-menu a.dropdown-item:hover svg {
  color: #1b55e2;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.show > .btn-success.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.show > .btn-info.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: none;
}

.btn.focus,
.btn:focus {
  box-shadow: none;
}

.btn-success:focus,
.btn-info:focus,
.btn-danger:focus,
.btn-warning:focus,
.btn-secondary:focus,
.btn-dark:focus,
.btn-outline-success:focus,
.btn-outline-info:focus,
.btn-outline-danger:focus,
.btn-outline-warning:focus,
.btn-outline-secondary:focus,
.btn-outline-dark:focus .btn-light-default:focus,
.btn-light-primary:focus,
.btn-light-success:focus,
.btn-light-info:focus,
.btn-light-danger:focus,
.btn-light-warning:focus,
.btn-light-secondary:focus,
.btn-light-dark:focus {
  box-shadow: none;
}

/*      Default Buttons       */

.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
  color: #fff !important;
  background-color: #2aebcb;
  border-color: #2aebcb;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active {
  color: #fff !important;
  background-color: #1b55e2;
  border-color: #1b55e2;
}

.show > .btn-primary.dropdown-toggle {
  color: #fff !important;
  background-color: #1b55e2;
  border-color: #1b55e2;
}

.btn-primary .caret {
  border-top-color: #fff;
}

.btn-group.open .btn-primary.dropdown-toggle {
  background-color: #c2d5ff;
}

.btn-secondary {
  color: #fff !important;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.btn-primary {
  color: #000 !important;
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.btn-secondary:hover,
.btn-secondary:focus {
  color: #fff !important;
  background-color: var(--primary-color);
  box-shadow: none;
  border-color: var(--primary-color);
}
.btn-primary:hover,
.btn-primary:focus {
  color: #000 !important;
  background-color: var(--secondary-color);
  box-shadow: none;
  border-color: var(--secondary-color);
}

.btn-secondary:active,
.btn-secondary.active {
  background-color: var(--primary-color);
  border-top: 1px solid var(--primary-color);
}
.btn-primary:active,
.btn-primary.active {
  background-color: var(--secondary-color);
  border-top: 1px solid var(--secondary-color);
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active {
  color: #fff !important;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active {
  color: #000 !important;
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.show > .btn-secondary.dropdown-toggle {
  color: #fff !important;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.show > .btn-primary.dropdown-toggle {
  color: #000 !important;
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.btn-secondary.disabled,
.btn-secondary.btn[disabled],
.btn-secondary:disabled {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.btn-primary.disabled,
.btn-primary.btn[disabled],
.btn-primary:disabled {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.btn-secondary .caret {
  border-top-color: #fff;
}
.btn-primary .caret {
  border-top-color: #fff;
}

.btn-info {
  color: #fff !important;
  background-color: #2196f3;
  border-color: #2196f3;
}

.btn-info:hover,
.btn-info:focus {
  color: #fff !important;
  background-color: #2196f3;
  box-shadow: none;
  border-color: #2196f3;
}

.btn-info:active,
.btn-info.active {
  background-color: #2196f3;
  border-top: 1px solid #2196f3;
}

.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active {
  color: #fff !important;
  background-color: #2196f3;
  border-color: #2196f3;
}

.show > .btn-info.dropdown-toggle {
  color: #fff !important;
  background-color: #2196f3;
  border-color: #2196f3;
}

.btn-info.disabled,
.btn-info.btn[disabled],
.btn-info:disabled {
  background-color: #2196f3;
  border-color: #2196f3;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover {
  color: #fff !important;
  background-color: #2196f3;
  border-color: #2196f3;
}

.btn-info.focus:active {
  color: #fff !important;
  background-color: #2196f3;
  border-color: #2196f3;
}

.btn-info:active:focus,
.btn-info:active:hover {
  color: #fff !important;
  background-color: #2196f3;
  border-color: #2196f3;
}

.open > .dropdown-toggle.btn-info.focus,
.open > .dropdown-toggle.btn-info:focus,
.open > .dropdown-toggle.btn-info:hover {
  color: #fff !important;
  background-color: #2196f3;
  border-color: #2196f3;
}

.btn-info .caret {
  border-top-color: #fff;
}

.btn-group.open .btn-info.dropdown-toggle {
  background-color: #bae7ff;
}

.btn-warning {
  color: #fff !important;
  background-color: #e2a03f;
  border-color: #e2a03f;
}

.btn-warning:hover,
.btn-warning:focus {
  color: #fff !important;
  background-color: #e2a03f;
  box-shadow: none;
  border-color: #e2a03f;
}

.btn-warning:active,
.btn-warning.active {
  background-color: #e2a03f;
  border-top: 1px solid #e2a03f;
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active {
  color: #0e1726;
  background-color: #e2a03f;
  border-color: #e2a03f;
}

.show > .btn-warning.dropdown-toggle {
  color: #0e1726;
  background-color: #e2a03f;
  border-color: #e2a03f;
}

.btn-warning.disabled,
.btn-warning.btn[disabled],
.btn-warning:disabled {
  background-color: #e2a03f;
  border-color: #e2a03f;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover {
  color: #fff !important;
  background-color: #e2a03f;
  border-color: #e2a03f;
}

.btn-warning.focus:active {
  color: #fff !important;
  background-color: #e2a03f;
  border-color: #e2a03f;
}

.btn-warning:active:focus,
.btn-warning:active:hover {
  color: #fff !important;
  background-color: #e2a03f;
  border-color: #e2a03f;
}

.open > .dropdown-toggle.btn-warning.focus,
.open > .dropdown-toggle.btn-warning:focus,
.open > .dropdown-toggle.btn-warning:hover {
  color: #fff !important;
  background-color: #e2a03f;
  border-color: #e2a03f;
}

.btn-warning .caret {
  border-top-color: #fff;
}

.btn-group.open .btn-warning.dropdown-toggle {
  background-color: #df8505;
}

.btn-danger {
  color: #fff !important;
  background-color: #e7515a;
  border-color: #e7515a;
}

.btn-danger:hover,
.btn-danger:focus {
  color: #fff !important;
  background-color: #e7515a;
  box-shadow: none;
  border-color: #e7515a;
}

.btn-danger:active,
.btn-danger.active {
  background-color: #e7515a;
  border-top: 1px solid #e7515a;
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active {
  color: #fff !important;
  background-color: #e7515a;
  border-color: #e7515a;
}

.show > .btn-danger.dropdown-toggle {
  color: #fff !important;
  background-color: #e7515a;
  border-color: #e7515a;
}

.btn-danger.disabled,
.btn-danger.btn[disabled],
.btn-danger:disabled {
  background-color: #e7515a;
  border-color: #e7515a;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover {
  color: #fff !important;
  background-color: #c00;
  border-color: #c00;
}

.btn-danger.focus:active {
  color: #fff !important;
  background-color: #c00;
  border-color: #c00;
}

.btn-danger:active:focus,
.btn-danger:active:hover {
  color: #fff !important;
  background-color: #c00;
  border-color: #c00;
}

.open > .dropdown-toggle.btn-danger.focus,
.open > .dropdown-toggle.btn-danger:focus,
.open > .dropdown-toggle.btn-danger:hover {
  color: #fff !important;
  background-color: #c00;
  border-color: #c00;
}

.btn-danger .caret {
  border-top-color: #fff;
}

.btn-group.open .btn-danger.dropdown-toggle {
  background-color: #a9302a;
}

.btn-dark {
  color: #fff !important;
  background-color: #3b3f5c;
  border-color: #3b3f5c;
}

.btn-dark:hover,
.btn-dark:focus {
  color: #fff !important;
  background-color: #3b3f5c;
  box-shadow: none;
  border-color: #3b3f5c;
}

.btn-dark:active,
.btn-dark.active {
  background-color: #3b3f5c;
  border-top: 1px solid #3b3f5c;
}

.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active {
  color: #fff !important;
  background-color: #3b3f5c;
  border-color: #3b3f5c;
}

.show > .btn-dark.dropdown-toggle {
  color: #fff !important;
  background-color: #3b3f5c;
  border-color: #3b3f5c;
}

.btn-dark.disabled,
.btn-dark.btn[disabled],
.btn-dark:disabled {
  background-color: #3b3f5c;
  border-color: #3b3f5c;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.btn-dark .caret {
  border-top-color: #fff;
}

.btn-group.open .btn-dark.dropdown-toggle {
  background-color: #484848;
}

.btn-success {
  color: black !important;
  background-color: #ffffff;
  border-color: gainsboro;
}

.btn-success:hover,
.btn-success:focus {
  color: black !important;
  background-color: #ffffff;
  border-color: gainsboro;
}

.btn-success:active,
.btn-success.active {
  background-color: #8dbf42;
  color: black !important;
  border-top: 1px solid #8dbf42;
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active {
  color: black !important;
  background-color: #ffffff;
  border-color: gainsboro;
}

.show > .btn-success.dropdown-toggle {
  color: black !important;
  background-color: #ffffff;
  border-color: gainsboro;
}

.btn-success.disabled,
.btn-success.btn[disabled],
.btn-success:disabled {
  background-color: #ffffff;
  border-color: black;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover {
  color: black !important;
  background-color: #ffffff;
  border-color: gainsboro;
}

.btn-success.focus:active {
  color: black !important;
  background-color: #ffffff;
  border-color: gainsboro;
}

.btn-success:active:focus,
.btn-success:active:hover {
  color: black !important;
  background-color: #ffffff;
  border-color: gainsboro;
}

.open > .dropdown-toggle.btn-success.focus,
.open > .dropdown-toggle.btn-success:focus,
.open > .dropdown-toggle.btn-success:hover {
  color: black !important;
  background-color: #ffffff;
  border-color: gainsboro;
}

.btn-success .caret {
  border-top-color: #fff;
}

/*-----/Button Light Colors------*/
.btn.box-shadow-none {
  border: none;
}

.btn.box-shadow-none:hover,
.btn.box-shadow-none:focus {
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
}

.box-shadow-none {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.btn.box-shadow-none:not(:disabled):not(.disabled).active,
.btn.box-shadow-none:not(:disabled):not(.disabled):active {
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
}

.show > .btn.box-shadow-none.dropdown-toggle {
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
}

.btn-group.open .btn-success.dropdown-toggle {
  background-color: #499249;
}

.btn-dismiss {
  color: #0e1726;
  background-color: #fff !important;
  border-color: #fff;
  padding: 3px 7px;
}

.btn-dismiss:hover,
.btn-dismiss:focus {
  color: #0e1726;
  background-color: #fff;
}

.btn-dismiss:active,
.btn-dismiss.active {
  background-color: #fff;
  border-top: 1px solid #fff;
}

.btn-group > .btn i {
  margin-right: 3px;
}

.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  -webkit-box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.16),
    0 0px 0px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.16),
    0 0px 0px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.16), 0 0px 0px 0 rgba(0, 0, 0, 0.12);
}

.btn-group > .btn + .dropdown-toggle {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.btn-group > .btn + .dropdown-toggle.btn-primary {
  border-left: 1px solid #4468fd;
}

.btn-group > .btn + .dropdown-toggle.btn-success {
  border-left: 1px solid #a3c66f;
}

.btn-group > .btn + .dropdown-toggle.btn-info {
  border-left: 1px solid #49acfb;
}

.btn-group > .btn + .dropdown-toggle.btn-warning {
  border-left: 1px solid #f5b455;
}

.btn-group > .btn + .dropdown-toggle.btn-danger {
  border-left: 1px solid #f1848b;
}

.btn-group > .btn + .dropdown-toggle.btn-dark {
  border-left: 1px solid #70767a;
}

.btn-group > .btn + .dropdown-toggle.btn-secondary {
  border-left: 1px solid #8353dc;
}

.btn-group.dropleft .dropdown-toggle-split {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group.dropleft .btn-primary:not(.dropdown-toggle-split) {
  border-left: 1px solid #4468fd;
}

.btn-group.dropleft .btn-success:not(.dropdown-toggle-split) {
  border-left: 1px solid #a3c66f;
}

.btn-group.dropleft .btn-info:not(.dropdown-toggle-split) {
  border-left: 1px solid #49acfb;
}

.btn-group.dropleft .btn-warning:not(.dropdown-toggle-split) {
  border-left: 1px solid #f5b455;
}

.btn-group.dropleft .btn-danger:not(.dropdown-toggle-split) {
  border-left: 1px solid #f1848b;
}

.btn-group.dropleft .btn-dark:not(.dropdown-toggle-split) {
  border-left: 1px solid #70767a;
}

.btn-group.dropleft .btn-secondary:not(.dropdown-toggle-split) {
  border-left: 1px solid #8353dc;
}

/*
    Btn group dropdown-toggle
*/
.btn .badge.badge-align-right {
  position: absolute;
  top: -1px;
  right: 8px;
}

.dropup .btn .caret {
  border-bottom-color: #0e1726;
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active {
  background-color: #1b55e2;
  color: #fff !important;
}

.show > .btn-outline-primary.dropdown-toggle {
  background-color: #1b55e2;
  color: #fff !important;
}

.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active {
  background-color: #8dbf42;
  color: #fff !important;
}

.show > .btn-outline-success.dropdown-toggle {
  background-color: #8dbf42;
  color: #fff !important;
}

.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active {
  background-color: #2196f3;
  color: #fff !important;
}

.show > .btn-outline-info.dropdown-toggle {
  background-color: #2196f3;
  color: #fff !important;
}

.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active {
  background-color: #e7515a;
  color: #fff !important;
}

.show > .btn-outline-danger.dropdown-toggle {
  background-color: #e7515a;
  color: #fff !important;
}

.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active {
  background-color: #e2a03f;
  color: #fff !important;
}

.show > .btn-outline-warning.dropdown-toggle {
  background-color: #e2a03f;
  color: #fff !important;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active {
  background-color: var(--primary-color);
  color: #fff !important;
}

.show > .btn-outline-secondary.dropdown-toggle {
  background-color: var(--primary-color);
  color: #fff !important;
}

.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active {
  background-color: #3b3f5c;
  color: #fff !important;
}

.show > .btn-outline-dark.dropdown-toggle {
  background-color: #3b3f5c;
  color: #fff !important;
}

.show > .btn-outline-primary.dropdown-toggle:after,
.show > .btn-outline-success.dropdown-toggle:after,
.show > .btn-outline-info.dropdown-toggle:after,
.show > .btn-outline-danger.dropdown-toggle:after,
.show > .btn-outline-warning.dropdown-toggle:after,
.show > .btn-outline-secondary.dropdown-toggle:after,
.show > .btn-outline-dark.dropdown-toggle:after,
.show > .btn-outline-primary.dropdown-toggle:before,
.show > .btn-outline-success.dropdown-toggle:before,
.show > .btn-outline-info.dropdown-toggle:before,
.show > .btn-outline-danger.dropdown-toggle:before,
.show > .btn-outline-warning.dropdown-toggle:before,
.show > .btn-outline-secondary.dropdown-toggle:before,
.show > .btn-outline-dark.dropdown-toggle:before {
  color: #fff !important;
}

.btn-outline-primary {
  border: 1px solid #1b55e2 !important;
  color: #1b55e2 !important;
  background-color: transparent;
  box-shadow: none;
}

.btn-outline-info {
  border: 1px solid #2196f3 !important;
  color: #2196f3 !important;
  background-color: transparent;
  box-shadow: none;
}

.btn-outline-warning {
  border: 1px solid #e2a03f !important;
  color: #e2a03f !important;
  background-color: transparent;
  box-shadow: none;
}

.btn-outline-success {
  border: 1px solid #8dbf42 !important;
  color: #8dbf42 !important;
  background-color: transparent;
  box-shadow: none;
}

.btn-outline-danger {
  border: 1px solid #e7515a !important;
  color: #e7515a !important;
  background-color: transparent;
  box-shadow: none;
}

.btn-outline-secondary {
  border: 1px solid var(--primary-color) !important;
  color: var(--primary-color) !important;
  background-color: transparent;
  box-shadow: none;
}

.btn-outline-dark {
  border: 1px solid #3b3f5c !important;
  color: #3b3f5c !important;
  background-color: transparent;
  box-shadow: none;
}

.btn-outline-primary:hover,
.btn-outline-info:hover,
.btn-outline-warning:hover,
.btn-outline-success:hover,
.btn-outline-danger:hover,
.btn-outline-secondary:hover,
.btn-outline-dark:hover {
  box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.1);
}

.btn-outline-primary:hover {
  color: #fff !important;
  background-color: #1b55e2;
}

.btn-outline-info:hover {
  color: #fff !important;
  background-color: #2196f3;
}

.btn-outline-warning:hover {
  color: #fff !important;
  background-color: #e2a03f;
}

.btn-outline-success:hover {
  color: #fff !important;
  background-color: #8dbf42;
}

.btn-outline-danger:hover {
  color: #fff !important;
  background-color: #e7515a;
}

.btn-outline-secondary:hover {
  color: #fff !important;
  background-color: var(--primary-color);
}

.btn-outline-dark:hover {
  color: #fff !important;
  background-color: #3b3f5c;
}

/*      Dropdown Toggle       */
.btn-rounded {
  -webkit-border-radius: 1.875rem !important;
  -moz-border-radius: 1.875rem !important;
  -ms-border-radius: 1.875rem !important;
  -o-border-radius: 1.875rem !important;
  border-radius: 1.875rem !important;
}

/*
    ===========================
        Data Marker ( dot )
    ===========================
*/
.data-marker {
  padding: 2px;
  border-radius: 50%;
  font-size: 18px;
  display: inline-flex;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.data-marker-success {
  background-color: #8dbf42;
}

.data-marker-warning {
  background-color: #e2a03f;
}

.data-marker-danger,
.data-marker-info,
.data-marker-dark {
  background-color: #e7515a;
}

.badge {
  font-weight: 600;
  line-height: 1.4;
  padding: 3px 6px;
  font-size: 12px;
  font-weight: 600;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
}

.badge:hover {
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}

.badge.badge-enabled {
  background-color: #8dbf42;
  color: #fff;
}

.badge.badge-disable {
  background-color: #e7515a;
  color: #fff;
}

.badge-pills {
  border-radius: 30px;
}

.badge-classic {
  border-radius: 0;
}

.badge-collapsed-img img {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 2px solid #ffffff;
  box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.3);
  margin-left: -21px;
}

.badge-collapsed-img.badge-tooltip img {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 2px solid #ffffff;
  box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.3);
  margin-left: -21px;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

.badge-collapsed-img.badge-tooltip img:hover {
  -webkit-transform: translateY(-5px) scale(1.02);
  transform: translateY(-5px) scale(1.02);
}

.badge-collapsed-img.translateY-axis img {
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

.badge-collapsed-img.translateY-axis img:hover {
  -webkit-transform: translateY(-5px) scale(1.02);
  transform: translateY(-5px) scale(1.02);
}

.badge-collapsed-img.rectangle-collapsed img {
  width: 45px;
  height: 32px;
}

.badge-collapsed-img.translateX-axis img {
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

.badge-collapsed-img.translateX-axis img:hover {
  -webkit-transform: translateX(5px) scale(1.02);
  transform: translateX(5px) scale(1.02);
}

.badge-primary {
  color: #fff;
  background-color: #1b55e2;
}

.badge-info {
  color: #fff;
  background-color: #2196f3;
}

.badge-success {
  color: #fff;
  background-color: #8dbf42;
}

.badge-danger {
  color: #fff;
  background-color: #e7515a;
}

.badge-warning {
  color: #fff;
  background-color: #e2a03f;
}

.badge-dark {
  color: #fff;
  background-color: #3b3f5c;
}

.badge-secondary {
  background-color: var(--primary-color);
}

.outline-badge-primary {
  color: #1b55e2;
  background-color: transparent;
  border: 1px solid #1b55e2;
}

.outline-badge-info {
  color: #2196f3;
  background-color: transparent;
  border: 1px solid #2196f3;
}

.outline-badge-success {
  color: #8dbf42;
  background-color: transparent;
  border: 1px solid #8dbf42;
}

.outline-badge-danger {
  color: #e7515a;
  background-color: transparent;
  border: 1px solid #e7515a;
}

.outline-badge-warning {
  color: #e2a03f;
  background-color: transparent;
  border: 1px solid #e2a03f;
}

.outline-badge-dark {
  color: #3b3f5c;
  background-color: transparent;
  border: 1px solid #3b3f5c;
}

.outline-badge-secondary {
  color: var(--primary-color);
  background-color: transparent;
  border: 1px solid var(--primary-color);
}

.outline-badge-primary:focus,
.outline-badge-primary:hover {
  background-color: #c2d5ff;
  color: #1b55e2;
}

.outline-badge-secondary:focus,
.outline-badge-secondary:hover {
  color: var(--primary-color);
  background-color: #dccff7;
}

.outline-badge-success:focus,
.outline-badge-success:hover {
  color: #8dbf42;
  background-color: #e6ffbf;
}

.outline-badge-danger:focus,
.outline-badge-danger:hover {
  color: #e7515a;
  background-color: #ffe1e2;
}

.outline-badge-warning:focus,
.outline-badge-warning:hover {
  color: #e2a03f;
  background-color: #ffeccb;
}

.outline-badge-info:focus,
.outline-badge-info:hover {
  color: #2196f3;
  background-color: #bae7ff;
}

.outline-badge-dark:focus,
.outline-badge-dark:hover {
  color: #3b3f5c;
  background-color: #acb0c3;
}

/*      Link     */
.badge[class*="link-badge-"] {
  cursor: pointer;
}

.link-badge-primary {
  color: #1b55e2;
  background-color: transparent;
  border: 1px solid transparent;
}

.link-badge-info {
  color: #2196f3;
  background-color: transparent;
  border: 1px solid transparent;
}

.link-badge-success {
  color: #8dbf42;
  background-color: transparent;
  border: 1px solid transparent;
}

.link-badge-danger {
  color: #e7515a;
  background-color: transparent;
  border: 1px solid transparent;
}

.link-badge-warning {
  color: #e2a03f;
  background-color: transparent;
  border: 1px solid transparent;
}

.link-badge-dark {
  color: #3b3f5c;
  background-color: transparent;
  border: 1px solid transparent;
}

.link-badge-secondary {
  color: var(--primary-color);
  background-color: transparent;
  border: 1px solid transparent;
}

.link-badge-primary:focus,
.link-badge-primary:hover {
  color: #1b55e2;
  background-color: transparent;
}

.link-badge-secondary:focus,
.link-badge-secondary:hover {
  color: #6f51ea;
  background-color: transparent;
}

.link-badge-success:focus,
.link-badge-success:hover {
  color: #2ea37d;
  background-color: transparent;
}

.link-badge-danger:focus,
.link-badge-danger:hover {
  color: #e7515a;
  background-color: transparent;
}

.link-badge-warning:focus,
.link-badge-warning:hover {
  color: #dea82a;
  background-color: transparent;
}

.link-badge-info:focus,
.link-badge-info:hover {
  color: #009eda;
  background-color: transparent;
}

.link-badge-dark:focus,
.link-badge-dark:hover {
  color: #454656;
  background-color: transparent;
}

/* Custom Dropdown*/
.custom-dropdown .dropdown-toggle::after,
.custom-dropdown-icon .dropdown-toggle::after,
.custom-dropdown .dropdown-toggle::before,
.custom-dropdown-icon .dropdown-toggle::before {
  display: none;
}

.custom-dropdown .dropdown-menu,
.custom-dropdown-icon .dropdown-menu {
  min-width: 11rem;
  border-radius: 4px;
  border: none;
  border: 1px solid #e0e6ed;
  z-index: 899;
  box-shadow: rgba(113, 106, 202, 0.2) 0px 0px 15px 1px;
  top: 15px !important;
  padding: 10px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
}

.custom-dropdown .dropdown-item.active,
.custom-dropdown .dropdown-item:active,
.custom-dropdown .dropdown-item:hover {
  color: #888ea8;
  background-color: #f1f2f3;
}

.custom-dropdown-icon .dropdown-item.active,
.custom-dropdown-icon .dropdown-item:active,
.custom-dropdown-icon .dropdown-item:hover {
  color: #888ea8;
  background-color: #f1f2f3;
}

.custom-dropdown .dropdown-item {
  font-size: 13px;
  color: #888ea8;
  display: block;
  font-weight: 700;
  padding: 11px 8px;
  font-size: 12px;
}

.custom-dropdown-icon .dropdown-item {
  font-size: 13px;
  color: #888ea8;
  display: block;
  font-weight: 700;
  padding: 11px 8px;
  font-size: 12px;
}

.custom-dropdown-icon .dropdown-menu .dropdown-item svg {
  width: 20px;
  height: 20px;
  margin-right: 3px;
  color: #888ea8;
}

.custom-dropdown .dropdown-item.active svg,
.custom-dropdown .dropdown-item:active svg,
.custom-dropdown .dropdown-item:hover svg {
  color: #1b55e2;
}

.custom-dropdown-icon .dropdown-item.active svg,
.custom-dropdown-icon .dropdown-item:active svg,
.custom-dropdown-icon .dropdown-item:hover svg {
  color: #1b55e2;
}

.status.rounded-tooltip .tooltip-inner {
  border-radius: 20px;
  padding: 8px 20px;
}

.tooltip-inner {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.popover {
  z-index: 999;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-bottom-color: #b3b3b3;
}

.help-block,
.help-inline {
  color: #555555;
}

.controls {
  position: relative;
}

.search-form-control {
  border-radius: 0.25rem;
}

/*  Table   */
.table-bordered {
  border: 1px solid #f1f2f3;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f1f2f3 !important;
}

.table > tbody > tr > td {
  vertical-align: middle;
  color: #818181;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
  white-space: pre-wrap;
  padding: 17px 20px;
}

.table > thead > tr > th {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 17px;
  letter-spacing: 1px;
  text-transform: capitalize;
  padding: 17px 20px;
}

.table > tbody > tr > td .usr-img-frame {
  background-color: #ebedf2;
  padding: 2px;
  width: 35px;
  height: 35px;
}

.table > tbody > tr > td .usr-img-frame img {
  width: 35px;
  margin: 0;
}

.table > tbody > tr > td .admin-name {
  font-weight: 700;
  color: #515365;
}

.table > tbody > tr > td .progress {
  width: 135px;
  height: 6px;
  margin: auto 0;
}

.table > tbody > tr > td svg.icon {
  width: 21px;
}

.table > tbody > tr > td .t-dot {
  background-color: #000;
  height: 11px;
  width: 11px;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 auto;
}

.table > tbody > tr > td svg.t-icon {
  padding: 5px;
  border-radius: 50%;
  font-size: 11px;
  vertical-align: sub;
  cursor: pointer;
}

.table > tbody > tr > td svg.t-icon.t-hover-icon:hover {
  background-color: #e7515a;
  color: #fff;
}

/* .table-bordered td,
.table-bordered th {
  border: 1px solid #0000003b;
} */

.table thead th {
  vertical-align: bottom;
  border-bottom: none;
}

.table-hover:not(.table-dark) tbody tr:hover {
  background-color: #f1f2f3 !important;
}

.table-controls > li > a svg {
  color: #25d5e4;
}

.table tr td .custom-dropdown.t-custom-dropdown a.dropdown-toggle,
.table tr td .custom-dropdown-icon.t-custom-dropdown a.dropdown-toggle {
  border-radius: 5px;
  border: 1px solid #d3d3d3;
}

.table-controls > li > a svg {
  color: #888ea8;
  width: 21px;
}

/*  Table Dark      */
.table.table-dark > thead > tr > th {
  color: #d3d3d3;
}

.table.table-dark > tbody > tr > td {
  color: #ffffff;
}

.table-dark {
  background-color: #060818;
}

.table-dark.table-hover tbody tr {
  background-color: #060818;
}

.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #191e3a !important;
}

.table-dark.table-hover tbody tr:hover {
  background-color: rgba(25, 30, 58, 0.631373);
}

.table.table-dark > tbody > tr > td i.t-icon {
  padding: 5px;
  border-radius: 50%;
  font-size: 14px;
  vertical-align: sub;
  cursor: pointer;
  color: #0e1726 !important;
}

table .badge-success,
table .badge-primary,
table .badge-warning,
table .badge-danger,
table .badge-info,
table .badge-secondary,
table .badge-dark {
  box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.2);
  will-change: opacity, transform;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
}

.table > tfoot > tr > th {
  color: #3b3f5c;
}

.table-vertical-align tr,
.table-vertical-align th,
.table-vertical-align td {
  vertical-align: middle !important;
}

.statbox .widget-content:before,
.statbox .widget-content:after {
  display: table;
  content: "";
  line-height: 0;
  clear: both;
}

.nav-tabs > li > a {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}

.btn-toolbar {
  margin-left: 0px;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .input-group > .form-control {
    flex: 1 1 auto;
    width: 1%;
  }
}

.spin {
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.toast-primary {
  background: #1b55e2;
}

.toast-header {
  background: #1b55e2;
  color: #fff;
  border-bottom: 1px solid rgba(33, 150, 243, 0.341176);
}

.toast-header .meta-time {
  color: #f1f2f3;
}

.toast-header .close {
  color: #f1f2f3;
  opacity: 1;
  text-shadow: none;
}

.toast-body {
  padding: 16px 12px;
  color: #fff;
}

/*  
    ==========================
        Background Colors  
    ==========================
*/
/*  
    Default  
*/
.bg-primary {
  background-color: #1b55e2 !important;
  border-color: #1b55e2;
  color: #fff;
}

.bg-success {
  background-color: #8dbf42 !important;
  border-color: #8dbf42;
  color: #fff;
}

.bg-info {
  background-color: #2196f3 !important;
  border-color: #2196f3;
  color: #fff;
}

.bg-warning {
  background-color: #e2a03f !important;
  border-color: #e2a03f;
  color: #fff;
}

.bg-danger {
  background-color: #e7515a !important;
  border-color: #e7515a;
  color: #fff;
}

.bg-secondary {
  background-color: #5c1ac3 !important;
  border-color: #5c1ac3;
  color: #fff;
}

.bg-dark {
  background-color: #fff;
  border-color: #3b3f5c;
  color: #fff;
}

/*  
    Light Background  
*/
.bg-light-primary {
  background-color: #c2d5ff !important;
  border-color: #c2d5ff;
  color: #2196f3;
}

.bg-light-success {
  background-color: #e6ffbf !important;
  border-color: #e6ffbf;
  color: #8dbf42;
}

.bg-light-info {
  background-color: #bae7ff !important;
  border-color: #bae7ff;
  color: #2196f3;
}

.bg-light-warning {
  background-color: #ffeccb !important;
  border-color: #ffeccb;
  color: #e2a03f;
}

.bg-light-danger {
  background-color: #ffe1e2 !important;
  border-color: #ffe1e2;
  color: #e7515a;
}

.bg-light-secondary {
  background-color: #dccff7 !important;
  border-color: #dccff7;
  color: #5c1ac3;
}

.bg-light-dark {
  background-color: #acb0c3;
  border-color: #acb0c3;
  color: #fff;
}

/*  
    Progress Bar
*/
.progress {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 12rem;
  background-color: #ebedf2;
  margin-bottom: 1.25rem;
  height: 16px;
  box-shadow: 1px 3px 20px 3px #f1f2f3;
}

.progress.progress-bar-stack .progress-bar:last-child {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

.progress .progress-bar {
  font-size: 10px;
  font-weight: 700;
  box-shadow: 0 2px 4px rgba(0, 69, 255, 0.15), 0 8px 16px rgba(0, 69, 255, 0.2);
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 100;
}

.progress:not(.progress-bar-stack) .progress-bar {
  border-radius: 16px;
}

.progress-sm {
  height: 4px;
}

.progress-md {
  height: 10px;
}

.progress-lg {
  height: 20px;
}

.progress-xl {
  height: 25px;
}

.progress-striped .progress-bar {
  background-image: -webkit-gradient(
    linear,
    0 100%,
    100% 0,
    color-stop(0.25, rgba(255, 255, 255, 0.15)),
    color-stop(0.25, transparent),
    color-stop(0.5, transparent),
    color-stop(0.5, rgba(255, 255, 255, 0.15)),
    color-stop(0.75, rgba(255, 255, 255, 0.15)),
    color-stop(0.75, transparent),
    to(transparent)
  );
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -moz-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}

.progress .progress-title {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}

.progress .progress-title span {
  align-self: center;
}

.progress .progress-bar.bg-gradient-primary {
  background-color: #1b55e2;
  background: linear-gradient(to right, #0081ff 0%, #0045ff 100%);
}

.progress .progress-bar.bg-gradient-info {
  background-color: #1b55e2;
  background-image: linear-gradient(to right, #04befe 0%, #4481eb 100%);
}

.progress .progress-bar.bg-gradient-success {
  background-color: #1b55e2;
  background-image: linear-gradient(to right, #3cba92 0%, #0ba360 100%);
}

.progress .progress-bar.bg-gradient-warning {
  background-color: #1b55e2;
  background-image: linear-gradient(to right, #f09819 0%, #ff5858 100%);
}

.progress .progress-bar.bg-gradient-secondary {
  background-color: #1b55e2;
  background-image: linear-gradient(to right, #7579ff 0%, #b224ef 100%);
}

.progress .progress-bar.bg-gradient-danger {
  background-color: #1b55e2;
  background-image: linear-gradient(to right, #d09693 0%, #c71d6f 100%);
}

.progress .progress-bar.bg-gradient-dark {
  background-color: #1b55e2;
  background-image: linear-gradient(to right, #2b5876 0%, #4e4376 100%);
}

.br-0 {
  border-radius: 0 !important;
}

.br-4 {
  border-radius: 4px !important;
}

.br-6 {
  border-radius: 6px !important;
}

.br-30 {
  border-radius: 30px !important;
}

.br-50 {
  border-radius: 50px !important;
}

.br-left-30 {
  border-top-left-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
}

.br-right-30 {
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}

.bx-top-6 {
  border-top-right-radius: 6px !important;
  border-top-left-radius: 6px !important;
}

.bx-bottom-6 {
  border-bottom-right-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}

/*      Badge Custom      */
.badge.counter {
  position: absolute;
  z-index: 2;
  right: 0;
  top: -10px;
  font-weight: 600;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  padding: 2px 0px;
  font-size: 12px;
}

.badge-chip {
  display: inline-block;
  padding: 0 25px;
  font-size: 16px;
  line-height: 42px;
  border-radius: 25px;
}

.badge-chip img {
  float: left;
  margin: 0px 10px 0px -26px;
  height: 44px;
  width: 44px;
  border-radius: 50%;
}

.badge-chip .closebtn {
  color: #f1f2f3;
  font-weight: bold;
  float: right;
  font-size: 20px;
  cursor: pointer;
}

.badge-chip .closebtn:hover {
  color: #fff;
}

/*-------text-colors------*/
.text-primary {
  color: #1b55e2 !important;
}

.text-success {
  color: #8dbf42 !important;
}

.text-info {
  color: #2196f3 !important;
}

.text-danger {
  color: #e7515a !important;
}

.text-warning {
  color: #e2a03f !important;
}

.text-dark {
  color: #3b3f5c !important;
}

.text-muted {
  color: #888ea8 !important;
}

.text-white {
  color: #fff !important;
}

.text-black {
  color: #000 !important;
}

/*-----border main------*/
.border {
  border: 1px solid !important;
}

.border-bottom {
  border-bottom: 1px solid !important;
}

.border-top {
  border-top: 1px solid !important;
}

.border-right {
  border-right: 1px solid !important;
}

.border-left {
  border-left: 1px solid !important;
}

.border-primary {
  border-color: #1b55e2 !important;
}

.border-info {
  border-color: #2196f3 !important;
}

.border-warning {
  border-color: #e2a03f !important;
}

.border-success {
  border-color: #8dbf42 !important;
}

.border-danger {
  border-color: #e7515a !important;
}

.border-secondary {
  border-color: #5c1ac3 !important;
}

.border-dark {
  border-color: #3b3f5c !important;
}

/*-----border style------*/
.border-dotted {
  border-style: dotted !important;
}

.border-dashed {
  border-style: dashed !important;
}

.border-solid {
  border-style: solid !important;
}

.border-double {
  border-style: double !important;
}

/*-----border width------*/
.border-width-1px {
  border-width: 1px !important;
}

.border-width-2px {
  border-width: 2px !important;
}

.border-width-3px {
  border-width: 3px !important;
}

.border-width-4px {
  border-width: 4px !important;
}

.border-width-5px {
  border-width: 5px !important;
}

.border-width-6px {
  border-width: 6px !important;
}

/*-----transform-position------*/
.position-absolute {
  position: absolute;
}

.position-static {
  position: static;
}

.position-fixed {
  position: fixed;
}

.position-inherit {
  position: inherit;
}

.position-initial {
  position: initial;
}

.position-relative {
  position: relative;
}

/* edit certificate */

/* ==========================================================================
   #GRID
   ========================================================================== */

.dropdown-toggle::after {
  float: right !important;
  margin-top: 18px !important;
}

.row-space {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.row-refine {
  margin: 0 -15px;
}

/* ==========================================================================
     #BOX-SIZING
     ========================================================================== */
/**
   * More sensible default box-sizing:
   * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
   */
html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

* {
  padding: 0;
  margin: 0;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
   * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
   *    on fieldsets.
   */
fieldset {
  min-width: 0;
  /* [1] */
  border: 0;
}

button {
  outline: none;
  background: none;
  border: none;
  font-family: inherit;
}

/* ==========================================================================
     #PAGE WRAPPER
     ========================================================================== */
.page-wrapper {
  min-height: 100vh;
}

body {
  font-family: "Sofia Pro", sans-serif;
  font-weight: 400;
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 15px;
}

h6 {
  font-size: 13px;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  display: inline-block;
  text-decoration: none;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

/* ==========================================================================
     #BACKGROUND
     ========================================================================== */

/* ==========================================================================
     #SPACING
     ========================================================================== */
.p-t-15 {
  padding-top: 15px;
}

.p-t-5 {
  padding-top: 5px;
}

.p-t-275 {
  padding-top: 275px;
}

@media (max-width: 767px) {
  .p-t-275 {
    padding-top: 120px;
  }
}

.p-t-165 {
  padding-top: 165px;
}

@media (max-width: 767px) {
  .p-t-165 {
    padding-top: 120px;
  }
}

.p-b-100 {
  padding-bottom: 100px;
}

@media (max-width: 767px) {
  .p-b-100 {
    padding-bottom: 250px;
  }
}

.m-r-45 {
  margin-right: 45px;
}

@media (max-width: 767px) {
  .m-r-45 {
    margin-right: 15px;
  }
}

/* ==========================================================================
     #WRAPPER
     ========================================================================== */
.wrapper {
  margin: 0 auto;
}

.wrapper--w680 {
  max-width: 680px;
}

.wrapper--w720 {
  max-width: 720px;
}

/* ==========================================================================
     #BUTTON
     ========================================================================== */
.btn-submit {
  display: block;
  margin-top: 32px;
  width: 100%;
  line-height: 50px;
  font-family: inherit;
  background: #00ad5f;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  text-transform: capitalize;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.btn-submit {
  background: var(--primary-color);
}

.btn-submit:hover {
  background: var(--primary-color);
}

.m-t-0 {
  margin-top: 0;
}

.m-t-15 {
  margin-top: 15px;
}

/* ==========================================================================
     #DATEPICKER
     ========================================================================== */
.daterangepicker {
  display: none;
  width: 570px;
  -webkit-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  padding: 30px 35px;
  padding-top: 27px;
  padding-bottom: 45px;
  margin-top: 5px;
  margin-left: -48px;
}

@media (max-width: 767px) {
  .daterangepicker {
    width: -webkit-calc(100% - 60px);
    width: -moz-calc(100% - 60px);
    width: calc(100% - 60px);
    padding: 25px 0px;
  }
}

.daterangepicker:before,
.daterangepicker:after {
  display: none;
}

.daterangepicker .calendar {
  margin: 0;
  width: 235px;
}

.daterangepicker .calendar th {
  font-size: 14px;
  color: #aaa;
  cursor: default;
}

.daterangepicker .calendar th.month {
  font-size: 16px;
  color: #555;
  font-weight: 700;
}

.daterangepicker .calendar th.next,
.daterangepicker .calendar th.prev {
  font-size: 20px;
  color: #ccc;
  text-align: center;
}

.daterangepicker .calendar.right {
  float: right;
}

@media (max-width: 767px) {
  .daterangepicker .calendar {
    width: 235px;
    max-width: 270px;
  }

  .daterangepicker .calendar.right,
  .daterangepicker .calendar.left {
    float: none !important;
    clear: none !important;
    margin: 0 auto !important;
  }

  .daterangepicker .calendar.left {
    margin-bottom: 30px !important;
  }
}

.daterangepicker .calendar-table {
  padding: 0;
}

.daterangepicker .calendar-table .table-condensed thead tr:nth-child(2) th {
  padding-top: 27px;
  padding-bottom: 17px;
}

.daterangepicker.ltr .calendar.left .calendar-table {
  padding: 0;
}

.daterangepicker_input {
  display: none;
}

.daterangepicker td {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  font-family: "Poppins", "Arial", "Helvetica Neue", sans-serif;
  text-align: center;
  font-size: 14px;
  color: #555;
}

.daterangepicker td.start-date,
.daterangepicker td.end-date {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #00ad5f;
}

.daterangepicker td.start-date.end-date {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.daterangepicker td.in-range {
  background: #d9f1e3;
}

.daterangepicker td.today {
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  border-radius: 50% !important;
  background: #c9c9c9;
}

.daterangepicker td.today.in-range {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
  background: #00ad5f;
}

.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
  background: transparent;
  color: #aaa;
}

.daterangepicker select {
  outline: none;
  font-family: inherit;
}

.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
  font-size: 14px;
  padding: 8px 4px;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background: #00ad5f;
}

.dropdown-datepicker {
  position: relative;
}

.dropdown-datepicker .daterangepicker {
  width: 280px;
  padding: 20px;
}

/* ==========================================================================
     #FORM
     ========================================================================== */
input,
textarea {
  outline: none;
  margin: 0;
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  width: 100%;
  font-size: 18px;
  font-family: inherit;
}

input:disabled {
  background: transparent;
  cursor: no-drop;
}

textarea {
  resize: none;
}

button {
  cursor: pointer;
}

.input-group {
  position: relative;
  margin-bottom: 10px;
  background: #ffffff;
  border-radius: 10px;
  padding-bottom: 3px;
  margin-top: 10px;
}

.input-group-symbol {
  position: absolute;
  font-size: 24px;
  color: #ccc;
  left: 18px;
  bottom: 10px;
}

.input-group-icon {
  position: relative;
}

.input-icon {
  font-size: 24px;
  color: #808080;
  position: absolute;
  line-height: 28px;
  right: 0;
  top: 0;
  width: 28px;
  background: #fff;
  text-align: center;
  cursor: pointer;
}

.input-icon::before {
  display: block;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.label {
  font-size: 12px;
  color: #555;
  text-transform: capitalize;
  display: block;
  font-weight: 700;
}

.input--style-1 {
  font-size: 12px;
  padding: 5px 0;
  padding-top: 0px;
  text-transform: uppercase;
  color: #808080;
  font-family: "Poppins", "Arial", "Helvetica Neue", sans-serif;
}

.input--style-1::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #808080;
}

.input--style-1:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #808080;
  opacity: 1;
}

.input--style-1::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #808080;
  opacity: 1;
}

.input--style-1:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #808080;
}

.input--style-1:-ms-input-placeholder {
  /* Microsoft Edge */
  color: #808080;
}

/* CHECKBOX */
.checkbox-row {
  padding-top: 18px;
}

.checkbox-container {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 12px;
  color: #fff;
  font-weight: 700;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.checkbox-container input:checked ~ .checkmark {
  background-color: #fff;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container .checkmark:after {
  left: 7px;
  top: 4px;
  width: 7px;
  height: 10px;
  border: solid #00ad5f;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Radio button */
.radio-row {
  padding-top: 18px;
}

.radio-container {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 12px;
  color: #fff;
  font-weight: 700;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radio-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.radio-container:hover input ~ .radio-checkmark {
  background-color: #fff;
}

.radio-container input:checked ~ .radio-checkmark {
  background-color: #fff;
}

.radio-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.radio-container input:checked ~ .radio-checkmark:after {
  display: block;
}

.radio-container .radio-checkmark:after {
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #00ad5f;
}

/* ==========================================================================
     #SELECT
     ========================================================================== */
.quantity {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  float: right;
}

@media (max-width: 575px) {
  .quantity {
    float: none;
    margin-top: 10px;
  }
}

.quantity > input {
  -webkit-appearance: none;
  width: 55px;
  text-align: center;
  font-size: 18px;
  color: #555;
  font-weight: 700;
}

.minus,
.plus {
  display: inline-block;
  width: 32px;
  height: 32px;
  line-height: 28px;
  text-align: center;
  border: 2px solid #ccc;
  font-size: 24px;
  color: #ccc;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.minus:hover,
.plus:hover {
  background: #ccc;
  color: #fff;
}

.list-room {
  list-style: none;
  max-height: 324px;
  overflow-y: auto;
  padding-right: 20px;
}

.list-room__item {
  margin-bottom: 20px;
}

.list-room__footer {
  padding-top: 22px;
  border-top: 1px solid #e5e5e5;
}

.list-room__name {
  margin-bottom: 27px;
  display: block;
  font-weight: 700;
  color: #999;
}

.list-person {
  list-style: none;
}

.list-person .list-person__item:last-child {
  margin-bottom: 0;
}

.list-person__item {
  margin-bottom: 34px;
}

.list-person__item::after {
  content: "";
  clear: both;
  display: table;
}

.list-person__item .name {
  font-size: 18px;
  color: #555;
  font-weight: 700;
  display: inline-block;
  margin-top: 5px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

#btn-add-room {
  font-family: inherit;
  font-size: 16px;
  color: #00ad5f;
  font-weight: 700;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

#btn-add-room:hover {
  text-decoration: underline;
}

.dropdown-select {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: -webkit-calc(100% + 2px);
  top: -moz-calc(100% + 2px);
  top: calc(100% + 2px);
  background: #fff;
  z-index: 999;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  padding: 30px 45px;
  padding-right: 25px;
}

.dropdown-select.show {
  display: block;
}

.open .input-icon::before {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

/* ==========================================================================
     #SELECT2
     ========================================================================== */
.select--no-search .select2-search {
  display: none !important;
}

.rs-select2 {
  width: 100%;
}

.rs-select2 .select2-container {
  width: 100% !important;
  outline: none;
}

.rs-select2 .select2-container .select2-selection--single {
  outline: none;
  border: none;
  height: 30px;
}

.rs-select2
  .select2-container
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 30px;
  padding-left: 0;
  color: #808080;
  font-size: 16px;
  text-transform: uppercase;
}

.rs-select2
  .select2-container
  .select2-selection--single
  .select2-selection__arrow {
  height: 28px;
  right: 4px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.rs-select2
  .select2-container
  .select2-selection--single
  .select2-selection__arrow
  b {
  display: none;
}

.rs-select2
  .select2-container
  .select2-selection--single
  .select2-selection__arrow:after {
  font-family: "Material-Design-Iconic-Font";
  content: "\f2f9";
  font-size: 24px;
  color: #808080;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.rs-select2
  .select2-container.select2-container--open
  .select2-selection--single
  .select2-selection__arrow::after {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.select2-container--open .select2-dropdown--below {
  border: none;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  margin-top: 2px;
  overflow: hidden;
}

.select2-container--default .select2-results__option {
  padding: 10px;
  font-size: 16px;
  color: #808080;
}

.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background: #00ad5f;
}

/* ==========================================================================
     #TAB
     ========================================================================== */
.tab-list {
  list-style: none;
  -webkit-border-top-right-radius: 10px;
  -moz-border-radius-topright: 10px;
  border-top-right-radius: 10px;
  -webkit-border-top-left-radius: 10px;
  -moz-border-radius-topleft: 10px;
  border-top-left-radius: 10px;
  padding: 0 55px;
  padding-top: 40px;
}

.tab-list::after {
  content: "";
  clear: both;
  display: table;
}

@media (max-width: 767px) {
  .tab-list {
    padding: 0 10px;
    padding-top: 40px;
  }
}

.tab-list__item {
  float: left;
}

.tab-list__link {
  display: block;
  text-transform: capitalize;
  font-family: "Poppins", "Arial", "Helvetica Neue", sans-serif;
  font-weight: 500;
  font-size: 24px;
  color: #999;
  padding: 0 20px;
}

.tab-list__link:hover {
  color: #fff;
}

@media (max-width: 767px) {
  .tab-list__link {
    font-size: 22px;
  }
}

.tab-list .active .tab-list__link {
  color: #fff;
}

/* .tab-content > .tab-pane {
    display: none;
    padding: 29px 75px;
    padding-bottom: 50px;
  } */

.tab-content > .tab-pane.active {
  display: block;
}

@media (max-width: 767px) {
  .tab-content > .tab-pane {
    padding: 30px;
    padding-bottom: 45px;
  }
}

/* ==========================================================================
     #TITLE
     ========================================================================== */
.title {
  font-size: 50px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 24px;
}

/* ==========================================================================
     #CARD
  
     ========================================================================== */
.card-3 {
  background: rgba(17, 25, 54, 0.9);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.form-control-file:disabled {
  background-color: transparent !important;
  cursor: no-drop !important;
}
