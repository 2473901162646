.table > thead {
  border-bottom: 1px solid #f3f4f6;
}
.radius-10 {
  border-radius: 10px;
}
.cursor-pointer {
  cursor: pointer;
}
.card {
  border: 1px solid #d7d7d7;
}
.sub-heading {
  color: #2e3130;
  font-size: 20px;
  font-weight: 600;
}
.body-heading {
  color: #3c3a3b;
  font-size: 16px;
  font-weight: 500;
}
.text-green {
  color: #b3d05c;
}
.bg-green {
  background-color: #b3d05c;
}
.text-orange {
  color: #ffac2f;
}
.bg-light-orange {
  background: rgba(255, 172, 47, 0.1);
}
.appointment-box {
  width: 50px;
  height: 50px;
}
.text-gray {
  color: #979797;
}
.appointments {
  border-bottom: 1px solid #e9e9e9;
  padding: 21px 0;
}
.appointments:last-child {
  border: 0;
}
.text-light-black {
  color: #636363;
}
.heading {
  font-size: 20px;
  color: #262d3d;
}
.search-input {
  width: 300px;
}
.search-input input {
  border: 1px solid #d7d7d7;
  border-right: 0;
}

.form-control:focus {
  border: 1px solid #d7d7d7;
  border-right: 0px;
  box-shadow: unset;
}

.dashboard-parent-class .form-control:focus {
  border: 1px solid #d7d7d7;
  border-right: 1px solid #d7d7d7;
  box-shadow: unset;
}

.appointment-parent-class .form-control:focus {
  border: 1px solid #d7d7d7;
  border-right: 1px solid #d7d7d7;
  box-shadow: unset;
}
.page-item a {
  padding: 8px 17px;
  border-block: 1px solid #d7d7d7;
  height: 42px;
}

.report-record span {
  color: #b3d05c;
}
.report-record {
  padding: 18px 15px;
  margin: 1rem 1.5rem 1rem 1.5rem;
  cursor: pointer;
}
.report-record.active {
  border-right: 5px solid var(--primary-color);
  border-radius: 8px;
  background-color: #b3d05c;
}
.report-record.active span {
  color: #fff;
}

.report-record:hover {
  background-color: #b3d05c24;
  border-radius: 8px;
}

.report-record.active:hover {
  background-color: var(--secondary-color);
}

.report-record.active:hover span {
  color: #fff;
}

.report-record:hover span {
  color: var(--secondary-color);
}

.upload-file {
  border: 2px dashed #ccc;
  text-align: center;
  padding: 40px 0;
}
.upload-file p {
  font-size: 14px;
  margin: 10px 0;
  color: #333;
}
.upload-file input {
  height: 100%;
}

.form-select {
  padding: 10px 10px;
}

.executive-heading {
  background-color: #3c3a3b;
  color: #fff;
  text-align: center;
  padding: 16px 0;
  border-radius: 10px 10px 0 0;
}
.executive-health-box ul li {
  font-size: 15px;
  color: #3c3a3b;
  border-bottom: 1px solid #ccc;
  padding: 11px 0;
}
.executive-health-box {
  background-color: #fff;
  padding: 0px 0px;
  box-shadow: 0 0 3px 3px #cccccc80;
  border-radius: 10px;
}

.executive-btn .btn {
  width: 43%;
  margin: 0 7px;
}
.executive-btn {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.executive-heading-bg {
  background-color: #b3d05c;
}
.page-item.active:hover .page-link {
  color: #fff;
}
.react-tel-input .form-control {
  border: 1px solid #bfc9d4;
  height: calc(1.4em + 1.4rem + 2px) !important;
  width: 100% !important;
  font-size: 15px !important;
}

.radius-8 {
  border-radius: 8px !important;
}

.dot-box {
  margin-top: -16px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}
.resposive-specialization-name {
  word-break: break-all;
  font-size: 12px;
  font-weight: 500;
}
.book-an-appointment-parent-class .user-img {
  padding: 0 10px !important;
  height: 70px;
}
.react-bootstrap-table table {
  table-layout: auto;
}

.react-bootstrap-table {
  overflow: scroll;
  white-space: nowrap;
}
input#date:focus {
  border-right: 1px solid #d7d7d7 !important;
}
.report-img {
  min-width: 37px;
}
.modal-backdrop {
  --bs-backdrop-bg: #00000052 !important;
}

.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 0.8rem;
}
.css-9l3uo3 {
  font-size: 0.8rem;
}
.keep-me-login {
  margin-left: -10px;
}
.sign-in-text {
  margin-left: -3px;
}
.My-health-badge {
  position: absolute;
  right: 0;
  background-color: #3c3a3bd6;
  padding: 15px 30px 15px 30px;
  color: #fff;
  font-size: 27px;
  margin-top: 17rem;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
