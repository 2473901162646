html {
  --primary-font: "Poppins', sans-serif";
  --primary-color: #3c3a3b;
  --secondary-color: rgba(179, 208, 92, 1);
  --light-color: rgba(233, 233, 233, 1);
  --error-or-danger-color: #a80000;
}

body {
  font-family: "Poppins", sans-serif;
  background-color: transparent !important;
}

.error-message-field-generic,
.error-message-field-generic p {
  color: var(--error-or-danger-color) !important;
  font-size: 13px;
}

.generic-color-secondary {
  color: #b3d05c;
}

.error-message-generic {
  text-align: center;
  color: var(--error-or-danger-color);
  font-weight: 600;
  font-size: 17px;
  /* text-transform: capitalize; */
  margin: 0.5rem 0;
}

.error-msg-center {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-message-generic p {
  color: var(--error-or-danger-color);
  word-break: break-word;
  white-space: break-spaces;
  margin: 0;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
}

.cursor-pointer {
  cursor: pointer;
}

.font-16 {
  font-size: 16px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-22 {
  font-size: 22px !important;
}

.font-35 {
  font-size: 35px !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.my-100 {
  margin: 100px 0;
}

.content-start-aligned {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.content-center-aligned {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-center-column-aligned {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.login-section {
  /* background-image: url(./../images/loginLogo.jpg); */
  height: 100vh;
  overflow: auto;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.login-section .margin-top-set {
  padding-top: 25vh;
  width: 100%;
}

.login-section .form-control {
  background-color: #e9e9e9 !important;
  font-family: var(--inter-font);
  font-size: 16px;
  font-weight: 600;
  color: #9c9c9c;
  border: 1px solid var(--primary-blue-color);
  margin-bottom: 1rem;
  padding: 26px 22px;
}

.login-section .show_pswd {
  position: absolute;
  right: 21px;
  top: 17px;
  font-size: 18px;
}

.login-section .form-control:focus {
  box-shadow: none;
  outline: none;
  border: 1px solid #5ed0ff;
}

.login-section .form-control::placeholder {
  font-family: var(--inter-font);
  font-size: 16px;
  font-weight: 600;
  color: #9c9c9c;
}

.login-section h2 {
  font-family: var(--inter-font);
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 2rem;
}

.login-section .card {
  padding: 1.5rem;
  border: 3px solid #efefef;
  box-shadow: 0px 4px 52px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.login-section .card .close-icon {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  font-size: 22px;
  cursor: pointer;
}

.login-section .card .submit-btn {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  background: var(--primary-color);
  width: 100%;
  padding: 15.5px 22px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  margin-top: 1rem;
}

.submit-btn {
  border-radius: 5px;
  background: var(--secondary-color);
  border: none;
  padding: 9.5px 22px;
  color: #000;
  font-size: 16px;
  font-weight: 400;
  margin-top: 0rem;
  margin-bottom: 1rem;
  width: 97%;
  height: 45px;
}

.resend-btn {
  border-radius: 5px;
  background: var(--primary-color);
  border: none;
  padding: 9.5px 22px;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  margin-top: 0rem;
  margin-bottom: 1rem;
  width: 97%;
  height: 45px;
}

.image-side-text {
  padding: 0px 20px;
  color: black;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-top: -6px;
}

.custom-toggle .dropdown-toggle::after {
  color: 000;
}

/* login section */
.auth-wrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  min-width: 100%;
  min-height: 100vh;
}

.auth-wrapper .otp-fields {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 50%;
  margin: 0 auto;
}

.auth-wrapper .otp-fields input {
  padding: 22px 10px;
  color: #262d3d;
  text-align: center;
  font-size: 31px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 80px;
}

.auth-wrapper .otp-fields input:focus {
  width: 90px;
  height: 90px;
}

.auth-wrapper .a-side {
  border-radius: 12px;
  background-color: #abca52;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  /* background-image: url(./../images/LoginLogoBannar.png);
  background-size: cover;
  background-position: center;
  border-radius: 12px;

  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative; */
}

.auth-wrapper .a-side img {
  width: 100%;
  object-fit: cover;
}

.auth-wrapper .auth-content {
  width: 60%;
}

.auth-wrapper .auth-icon {
  font-size: 30px;
}

.auth-wrapper .show_pswd {
  position: absolute;
  right: 12px;
  top: 45px;
  font-size: 18px;
  cursor: pointer;
}

.auth-wrapper .submit-btn {
  border-radius: 4px;
  background: var(--secondary-color);
  border: none;
  padding: 14px 22px;
  color: #fff;
  width: 100%;

  color: #fff;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 30px 0;
  height: unset;
}

.auth-wrapper .resend-code-text {
  color: #829e2e;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-decoration-line: underline;
  margin-top: 10px;
  cursor: pointer;
}

.auth-wrapper .already-account-text {
  color: #979797;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.1px;
  margin: 0;
}

.auth-wrapper .already-account-text a {
  color: #3c3a3b;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1px;
}

#login-label {
  color: #202020;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1px;
  text-transform: uppercase;
}

#forget-password {
  color: #34aa44;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1px;
}

.input-group>.form-control:focus {
  z-index: inherit;
}

.auth-content .sign-in-text {
  color: #262d3d;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.auth-content .sign-in-header {
  color: #b3d05c;
  font-size: 45px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 2rem;
}

.auth-content .sign-in-detail-text {
  color: #979797;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.1px;
}

.auth-wrapper label {
  color: #202020;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1px;
  margin-bottom: 8px !important;
}

/* week view calendar style */

.week-view-calendar {
  font-family: Arial, sans-serif;
  max-width: 600px;
  margin: 0 auto;
  position: relative;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.week-days-row {
  display: flex;
  justify-content: space-between;
  /* background-color: #f0f0f0;
  border: 1px solid #ccc; */
  border-radius: 5px;
}

.day-cell {
  text-align: center;
  flex: 1;
  padding: 10px;
  cursor: pointer;
}

.day-cell.disabled {
  cursor: no-drop;
  color: #a49f9f;
}

.day-weekday {
  font-size: 14px;
  color: lightgrey;
}

.day-number {
  font-size: 18px;
  font-weight: bold;
}

.appointment-section-style .day-number {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 10px;
}

.previous-button,
.next-button {
  position: absolute;
  top: 63%;
  transform: translateY(-50%);
  font-size: 30px;
  cursor: pointer;
}

.book-now-form-parent-class .previous-button,
.book-now-form-parent-class .next-button {
  position: absolute;
  top: 77%;
  transform: translateY(-50%);
  font-size: 30px;
  cursor: pointer;
}

.previous-button,
.next-button {
  position: absolute;
  top: 70%;
  transform: translateY(-50%);
  font-size: 30px;
  cursor: pointer;
}

.previous-button {
  left: 3px;
}

.next-button {
  right: 3px;
}

.week-view-calendar .active {
  background-color: var(--secondary-color);
  color: #fff;
  border-radius: 12px;
}

.active .day-weekday {
  background-color: var(--secondary-color);
  color: #fff;
  border-radius: 12px;
}

.week-view-calendar select {
  word-wrap: normal;
  border: none;
  font-size: 15px;
  background: unset;
}

.blood-group-style {
  background: #e41b1b;
  margin: 10px;
  padding: 0px 5px;
  border-radius: 2px;
  color: #fff;
  font-size: 12px;
}

/* remaining styling */
.fixed-height-card {
  height: 510px;
}

.header-profile-section {
  font-size: 13px;
  color: #7d7d7d;
}

.header-profile-section-main {
  font-size: 13px;
  font-weight: 500;
}

.header-button-style {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.blood-group-style {
  background: #e41b1b;
  margin: 10px;
  padding: 0px 5px;
  border-radius: 2px;
  color: #fff;
  font-size: 12px;
}

/* remaining styling */
.fixed-height-card {
  height: 450px;
  overflow: auto;
}

.appointment-section-style .fixed-height-card {
  height: 235px;
  overflow: auto;
}

.appointment-section-style.fixed-height-card-on-noRecord {
  height: 402px;
}

.appointment-section-style .fixed-height-card {
  height: 235px;
  overflow: auto;
}

.upComing-appointment-section-style.fixed-height-card-on-noRecord {
  height: 730px;
}

.appointment-page-parent-class .fixed-height-card {
  height: 735px;
  overflow: auto;
}

.billing-parent-class .fixed-height-card {
  height: 780px;
  overflow: auto;
}

.report-parent-class .fixed-height-card {
  height: 780px;
  overflow: auto;
}

.prescription-parent-class .fixed-height-card {
  height: 780px;
  overflow: auto;
}

.fixed-height-card-3 {
  height: 300px;
  overflow: auto;
}

.calendar-view-green-style {
  padding: 20px 20px;
  background: rgba(0, 188, 19, 0.1);
  border-radius: 50%;
  line-height: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.calendar-view-orange-style {
  padding: 15px;
  background: rgba(255, 172, 47, 0.1);
  border-radius: 50%;
}

.appointment-section-style {
  line-height: 10px;
  border-bottom: none;
}

.appointment-section-style:not(:last-child) {
  border-bottom: 1px solid #e9e9e9;
}

.apointment-section-doctor-name {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.apointment-section-specialization {
  font-size: 12px;
  font-weight: 400;
  color: #979797;
}

.apointment-section-time {
  font-size: 14px;
  font-weight: 400;
  text-align: end;
}

.apointment-section-confirmed-status {
  font-size: 15px;
  font-weight: 600;
  /* color: var(--secondary-color); */
  color: red;
}

.apointment-section-pending-status {
  font-size: 12px;
  font-weight: 500;
  color: #ffac2f;
}

.card-header .see-more-style {
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
  color: var(--secondary-color);
}

/* latest reports styling */

.report-blood-test-style {
  background-color: var(--secondary-color);
  border-radius: 4px;
  padding: 8px;
}

.report-ultrasound-test-style {
  background-color: var(--secondary-color);
  border-radius: 4px;
  padding: 8px;
}

.report-name-style {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
}

.report-date-style {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin: 0;
}

.report-arrow-style {
  font-size: 22px;
  cursor: pointer;
  /* padding: 4px; */
}

.prescription-table-parent-class .table tbody tr {
  border-bottom: 1px solid #dee2e6 !important;
}

.prescription-table-parent-class .table tbody td {
  padding-top: 15px;
  padding-bottom: 15px;
}

.prescription-table-parent-class .table thead tr {
  border-bottom: 1px solid #dee2e6 !important;
}

.prescription-table-parent-class .table thead th {
  padding-top: 15px;
  padding-bottom: 15px;
}

/* .prescription-table-parent-class .table > thead > tr > th {
  color: #636363;
  font-weight: 400;
  font-size: 13px;
} */

.book-an-appointment-parent-class .user-img {
  background: #d9d9d978;
  padding: 10px;
  border-radius: 8px;
}

.book-an-appointment-parent-class .doc-img>img {
  height: 50px;
  object-fit: cover;
  width: 50px;
  /* border: 1px solid rgba(60, 58, 59, 0.296); */
  border: 1px solid var(--secondary-color);
}

.book-an-appointment-parent-class .table>tbody>tr>td {
  vertical-align: middle;
  color: #818181;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
  white-space: pre-wrap;
  padding: 5px 0px;
}

.book-appointment-image-style {
  background-color: #fff;
  padding: 5px;
  color: #3c3a3b;
}

.labName-font-size {
  font-size: 13px;
}

.specializatoin-fixed-height {
  height: 650px;
  overflow-y: auto;
  overflow-x: hidden;
}

.appointment-fixed-height {
  height: 542px;
  overflow-y: auto;
  overflow-x: hidden;
}

.book-appointment-search.search-input {
  width: 100%;
}

.consult-view-text-color-style {
  color: #999999;
}

.consult-view-img {
  background-color: var(--secondary-color);
  border-radius: 0% 7% 0% 7%;
  width: 200px;
  height: 210px;
  object-fit: cover;
}

.consult-view-button {
  text-align: center;
}

.table-no-record-style {
  text-align: center;
  font-size: 18px;
  color: #000;
  margin-top: 15rem;
}

.graph-no-record-style {
  text-align: center;
  font-size: 18px;
  color: #000;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.date-dropdown {
  /* width: 100px; */
  cursor: pointer;
}

.auth-wrapper .form-control:focus {
  border: 1px solid #d7d7d7;
  border-right: 1px solid #d7d7d7;
  box-shadow: unset;
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  z-index: 0 !important;
}

.auth-wrapper .input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(179 208 92 / 27%);
  /* Color of the thumb */
  border-radius: 12px;
  /* Rounded corners for the thumb */
}

::-webkit-scrollbar {
  width: 5px;
  /* Width of the scrollbar */
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgb(179 208 92 / 27%);
}

.book-now-form-parent-class .form-control:focus {
  border: 1px solid #d7d7d7 !important;
  box-shadow: unset;
}

.book-now-form-parent-class .form-control:focus+.form-label {
  top: -10px;
  left: 14px;
  z-index: 10;
  font-size: 13px;
  font-weight: 500;
  color: var(--secondary-color);
}

.consult-view-modal-parent .form-control:focus {
  border: 1px solid #d7d7d7 !important;
  box-shadow: unset;
}

.consult-view-modal-parent .form-control:focus+.form-label {
  top: -10px;
  left: 14px;
  z-index: 10;
  font-size: 13px;
  font-weight: 500;
  color: var(--secondary-color);
}

.book-now-form-parent-class .form-control:not(:placeholder-shown)+.form-label {
  top: -10px;
  left: 14px;
  z-index: 10;
  font-size: 13px;
  font-weight: 500;
}

.consult-view-modal-parent .form-control:not(:placeholder-shown)+.form-label {
  top: -10px;
  left: 14px;
  z-index: 10;
  font-size: 13px;
  font-weight: 500;
}

.book-now-form-parent-class .form-label {
  position: absolute;
  top: 12px;
  left: 15px;
  padding: 0 4px;
  font-size: 14px;
  transition: 0.1s;
  background-color: white;
  color: #adadad;
}

.consult-view-modal-parent .form-label {
  position: absolute;
  top: 12px;
  left: 15px;
  padding: 0 4px;
  font-size: 14px;
  transition: 0.1s;
  background-color: white;
  color: #adadad;
}

.book-now-form-parent-class .form-control {
  border-radius: 5px;
  border: 1px solid #adadad5e;
  padding: 0.75rem 1.6rem;
}

.book-now-form-parent-class .form-control:focus {
  border: 1px solid #1976d2;
  box-shadow: none;
}

.consult-view-modal-parent .form-control {
  border-radius: 5px;
  border: 1px solid #adadad5e;
  padding: 0.75rem 1.6rem;
}

.consult-view-modal-parent .form-control:focus {
  border: 1px solid #1976d2;
  box-shadow: none;
}

.book-now-form-parent-class ::placeholder {
  color: transparent;
}

.book-now-form-parent-class .inputContainer {
  margin-bottom: 1.5rem;
  position: relative;
}

.book-now-form-parent-class .option-style input {
  width: inherit;
}

.consult-view-modal-parent ::placeholder {
  color: transparent;
}

.consult-view-modal-parent .inputContainer {
  margin-bottom: 1.5rem;
  position: relative;
}

.consult-view-modal-parent .option-style input {
  width: inherit;
}

.custom-radio input[type="radio"]:checked+.custom-control-label::before {
  border-color: var(--secondary-color) !important;
  /* Replace with your desired color */
  background-color: var(--secondary-color) !important;
  /* Replace with your desired color */
}

.custom-radio input[type="radio"]:checked:focus+.custom-control-label::before {
  box-shadow: 0 0 0 1px #1976d2, 0 0 0 3px rgba(25, 118, 210, 0.25);
  /* Replace with your desired color */
}

textarea.form-control {
  min-height: calc(4.5em + 0.75rem + calc(var(--bs-border-width) * 2));
}

.answer-styling {
  border: none !important;
  border-bottom: 2px solid !important;
  border-radius: 0px !important;
}

.book-now-form-parent-class .answer-styling.form-control:focus {
  border: none !important;
  border-bottom: 2px solid !important;
  border-radius: 0px !important;
}

.tabs-style.active {
  padding: 10px 20px;
  background: #b3d05c;
  color: #000;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 600;
}

.tabs-style {
  padding: 10px 20px;
  font-size: 15px;
  font-weight: 600;
  width: 100%;
  text-align: center;
}

.tabs-main-style {
  background: #fff;
  padding: 15px;
  border-radius: 8px;
  justify-content: center;
  box-shadow: 0 0 12px -1px rgba(0, 0, 0, 0.15) !important;
}

.slot-styling {
  background: lightgrey;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  height: fit-content;
}

.slot-styling.active {
  background: var(--secondary-color);
  padding: 10px;
  border-radius: 8px;
}

.date-selection-style {
  margin-top: -2px;
  width: 55%;
}

.prescription-date-style.date-selection-style {
  width: 100%;
}

.prescription-d-flex-property {
  display: flex;
  align-items: center;
  justify-content: start;
}

.search-input-prescription {
  margin-top: 10px;
  margin-left: 10px;
  width: 100%;
}

.consult-view-card-height {
  max-height: 423px;
  height: 423px;
  overflow: hidden;
}

/* YourComponent.css */

/* Style for the entire date range picker container */
.ant-date-range-picker {
  display: inline-block;
  position: relative;
  font-family: Arial, sans-serif;
  cursor: pointer;
}

/* Style for the date input fields */
.ant-date-range-picker input[type="text"] {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 8px 12px;
  margin: 0;
  width: 220px;
  transition: border-color 0.3s;
}

/* Style for the separator between input fields */
.ant-date-range-separator {
  margin: 0 8px;
}

/* Style for the calendar container */
.ant-date-range-calendar {
  position: absolute;
  top: 100%;
  width: 100%;
  left: 0;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  border-radius: 4px;
}

.rdrCalendarWrapper {
  color: #000000;
  font-size: 12px;
  width: 100% !important;
}

.rdrDateDisplayItem {
  width: 220px !important;
}

.rdrMonth {
  width: 100% !important;
}

.rdrDateDisplayWrapper {
  background-color: rgb(255 255 255) !important;
}

.rdrCalendarWrapper {
  margin-top: -8px;
}

.rdrDayPickerRangeWrapper .rdrDayPickerRange {
  background-color: rgba(0, 128, 0, 0.3) !important;
  /* Light green background color */
}

.ant-date-range-picker input::placeholder {
  color: rgba(128, 128, 128, 0.524) !important;
  display: block;
  /* Add more styles as needed */
}

.slot-fix-height {
  min-height: 300px;
  height: 70px;
  overflow: auto;
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(4, 1fr);
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* Create 4 columns */
  grid-gap: 16px;
  /* Adjust the gap between items as needed */
}

.reset-password-lock {
  position: absolute;
  font-size: 18px;
  cursor: pointer;
  right: 35px;
  bottom: 9.7rem;
}

.forget-password-text a {
  color: #34aa44;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1px;
}

.first-last-name-style {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 55px;
  height: 55px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #fff;
  background-color: var(--secondary-color);
}

.lastlogin-style {
  font-size: 12px;
}

.truncate {
  width: 370px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-profile {
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.executive-appointment-select {
  margin-top: -25px;
  width: 50%;
}

.upload-image-aws-style {
  margin-left: -1.3rem;
  margin-top: 17px;
  z-index: 0;
  opacity: 0;
  width: 3%;
}

.upload-image-body {
  margin-top: 30px;
  margin-left: -18px;
  z-index: 1;
  font-size: 20px;
}

.upload-icon-styling {
  background: #e5f8e7;
  border-radius: 50%;
  padding: 2px;
}

.header-profile-section-mob {
  display: none;
}

.pin-custom-input {
  margin: 0;
}

.pin-custom-input .input-group-text {
  background: transparent;
  border-right: 0;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  color: #3b3f5c;
  font-size: 15px;
  letter-spacing: 1px;
  height: calc(1.4em + 1.4rem + 2px);
  padding: 0.9rem 0rem 0.9rem 0.4rem;
  font-weight: 400;
  line-height: 1.5;
}

.react-tel-input :is(.selected-flag:hover, .selected-flag:focus) {
  background-color: transparent !important;
}

.react-tel-input .form-control {
  border: 1px solid #e2e5ed;
  height: calc(1.4em + 1.4rem + 2px);
  width: 100%;
  font-size: 15px;
  padding-left: 48px;
}

.react-tel-input .flag-dropdown {
  border: 1px solid #e2e5ed;
  border-right: 0;
}

.react-tel-input .selected-flag,
.react-tel-input .flag-dropdown.open .selected-flag {
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.react-tel-input .flag-dropdown.input-field-error {
  border: 1px solid #e93535 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-right: 0;
}

.react-tel-input input.input-field-error {
  border: 1px solid #e93535 !important;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.pin-custom-input :is(input, input:focus, .form-control:focus) {
  border-left: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border: 1px solid #e2e5ed;
  padding-left: 2px;
}

.consult-view-parent-class .card::before {
  position: absolute;
  top: 107px;
  right: -13px;
  content: "";
  background: linear-gradient(45deg, #abca52 65%, #000 50%);
  height: 25px;
  width: 25px;
  transform: rotate(45deg);
}

.consult-view-parent-class .card::after {
  position: absolute;
  content: attr(data-label);
  top: 92px;
  right: -4px;
  padding: 5px 0px;
  width: 5rem;
  background: #a4c254;
  color: #000;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.auth-wrapper .card::before {
  position: absolute;
  right: -8px;
  content: "";
  background: linear-gradient(90deg, #abca52 73%, #3c3a3b 12%);
  height: 87.5px;
  width: 25px;
  border-radius: 9px;
  bottom: 28.3%;
}

.auth-wrapper .card::after {
  position: absolute;
  content: attr(data-label);
  right: -1px;
  padding: 15px 0px;
  width: 9rem;
  background: #3c3a3b;
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  bottom: 27.9%;
  box-shadow: 1px 3px 4px 0px rgba(0, 0, 0, 0.05);
  transform: translate(1px, -27.9%);
}

/* .auth-wrapper{
  position: relative;
} */
.appointment-detail-heading-style {
  color: #4d4d4d;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.appointment-detail-heading-text-style {
  color: #979797;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.appointment-check-in {
  background: #bbe1481a;
  padding: 3px 10px;
  border-radius: 5px;
  color: #8aab25;
}

.appointment-cancel {
  background: #fdebeb;
  padding: 3px 10px;
  border-radius: 5px;
  color: #e93535;
}

.appointment-check-out {
  background: #00a3ff1a;
  padding: 3px 10px;
  border-radius: 5px;
  color: #00a3ff;
}

.appointment-pending {
  background: #fff4e7;
  padding: 3px 10px;
  border-radius: 5px;
  color: #ff8c06;
}

.appointment-in-triage {
  background: #3c2a961a;
  padding: 3px 10px;
  border-radius: 5px;
  color: #3c2a96;
}

.border-doctor-detail {
  border: 1px solid #00000026;
}

.appointment-section-style.active {
  background-color: rgb(43 50 20 / 13%);
  border-radius: 5px;
}

.appointment-page-parent-class .table tbody tr {
  cursor: pointer;
}

.book-an-appointment-parent-class .table tbody tr {
  cursor: pointer;
}

.ehc-text-style {
  color: var(--secondary-color);
}

.king-icon-style {
  margin-top: -5px;
}

.rounded-images-bg-color {
  background: #000000b3;
}

.my-health-logo-bannar {
  position: absolute;
  right: -8rem;
  bottom: -2.5rem;
}

.lab-test-logo {
  height: 50px;
  object-fit: cover;
  width: 50px;
  border: 1px solid rgba(60, 58, 59, 0.296);
  border-radius: 50%;
}

.result-item.active {
  background-color: var(--secondary-color);
  color: white;
  cursor: pointer;
}

/* Style for non-active result items */
.result-item {
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 5px;
}

/* Add hover effect for non-active items */
.result-item:not(.active):hover {
  background-color: #f0f0f0;
}

.normal-color {
  color: var(--secondary-color);
  margin-right: 10px;
}

.unknown-color {
  color: lightgrey;
  margin-right: 10px;
}

.high-color {
  color: red;
  margin-right: 10px;
}

.low-color {
  color: rgb(244 156 91);
  margin-right: 10px;
}

::-webkit-media-controls-download-button {
  display: none !important;
}

.report-parent-class .card-body table {
  border-width: 0px;
  empty-cells: show;
  width: 100% !important;
  height: 534px;
  position: relative;
  background-color: #ffffff;
  margin-top: 20px !important;
}

.prescription-parent-class .card-body table {
  border-width: 0px;
  empty-cells: show;
  width: 100% !important;
  height: 534px;
  position: relative;
  background-color: #ffffff;
  margin-top: 20px !important;
}

.billing-parent-class .card-body table {
  border-width: 0px;
  empty-cells: show;
  width: 100% !important;
  height: 534px;
  position: relative;
  background-color: #ffffff;
  margin-top: 20px !important;
}

/* .billing-parent-class .card-body table tbody img {
  width: 850px !important;
    height: 150px !important;
    margin-top: -31px !important;
} */
.text-color {
  color: #858585;
}

.appointment-parent-class .react-datepicker-wrapper {
  display: inline-block;
  padding: 4px !important;
  width: 50% !important;
  /* border: 1px solid lightgray !important; */
  border-radius: 6px;
}

.book-now-form-parent-class .react-datepicker-wrapper {
  display: inline-block;
  padding: 4px !important;
  width: 50% !important;
  /* border: 1px solid lightgray !important; */
  border-radius: 6px;
}

.book-now-form-parent-class .no-record-datePicker .react-datepicker-wrapper {
  display: inline-block;
  padding: 4px !important;
  width: 26% !important;
  /* border: 1px solid lightgray !important; */
  border-radius: 6px;
}

.appointment-parent-class .active-calender .react-datepicker-wrapper {
  display: inline-block;
  padding: 4px !important;
  width: 62% !important;
  /* border: 1px solid lightgray !important; */
  border-radius: 6px;
}

.book-now-form-parent-class .active-calender .react-datepicker-wrapper {
  display: inline-block;
  padding: 4px !important;
  width: 58% !important;
  /* border: 1px solid lightgray !important; */
  border-radius: 6px;
}

.book-now-form-parent-class .no-record-datePicker .active-calender .react-datepicker-wrapper {
  display: inline-block;
  padding: 4px !important;
  width: 26% !important;
  /* border: 1px solid lightgray !important; */
  border-radius: 6px;
}

.book-now-form-parent-class .no-record-datePicker-loading .active-calender .react-datepicker-wrapper {
  display: inline-block;
  padding: 4px !important;
  width: 26% !important;
  /* border: 1px solid lightgray !important; */
  border-radius: 6px;
}

.appointment-parent-class .react-datepicker__navigation-icon {
  top: 5px !important;
}

.book-now-form-parent-class .react-datepicker__navigation-icon {
  top: 5px !important;
}

.dashboard-parent-class .react-datepicker__navigation-icon {
  top: 5px !important;
}

.dashboard-parent-class .active-calender .react-datepicker-wrapper {
  display: inline-block;
  padding: 4px !important;
  width: 62% !important;
  /* border: 1px solid lightgray !important; */
  border-radius: 6px;
}

.dashboard-parent-class .select-dropdown-icon-non-active {
  position: absolute;
  top: 21%;
  left: 6rem;
  transform: translateY(-50%);
  font-size: 24px;
  cursor: pointer;
}

.appointment-parent-class .select-dropdown-icon-non-active {
  position: absolute;
  top: 19%;
  left: 6rem;
  transform: translateY(-50%);
  font-size: 24px;
  cursor: pointer;
}

.book-now-form-parent-class .select-dropdown-icon-non-active {
  position: absolute;
  top: 19%;
  left: 7rem;
  transform: translateY(-50%);
  font-size: 20px;
  cursor: pointer;
}

.book-now-form-parent-class .no-record-datePicker .select-dropdown-icon-non-active {
  position: absolute;
  top: 38.5%;
  left: 8rem;
  transform: translateY(-38%);
  font-size: 20px;
  cursor: pointer;
}

.book-now-form-parent-class .no-record-datePicker-loading .select-dropdown-icon-non-active {
  position: absolute;
  top: 52.5%;
  left: 8rem;
  transform: translateY(-38%);
  font-size: 20px;
  cursor: pointer;
}

.appointment-parent-class .select-dropdown-icon {
  position: absolute;
  top: 50%;
  left: 6rem;
  transform: translateY(-50%);
  font-size: 20px;
  cursor: pointer;
}

.book-now-form-parent-class .select-dropdown-icon {
  position: absolute;
  top: 50%;
  left: 6rem;
  transform: translateY(-50%);
  font-size: 20px;
  cursor: pointer;
}

.book-now-form-parent-class .no-record-datePicker .select-dropdown-icon {
  left: 7rem;
}

.dashboard-parent-class .react-datepicker-wrapper {
  display: inline-block;
  padding: 4px !important;
  width: 50% !important;
  /* border: 1px solid lightgray !important; */
  border-radius: 6px;
}

.dashboard-parent-class .select-dropdown-icon {
  position: absolute;
  top: 50%;
  left: 6rem;
  transform: translateY(-50%);
  font-size: 20px;
  cursor: pointer;
}

.react-datepicker__input-container:focus-visible {
  border: 1px solid lightgray !important;
}

.form-control.react-datepicker-ignore-onclickoutside:focus {
  border: 1px solid lightgray !important;
  border-right: 1px solid lightgray !important;
}

.high-label-style {
  position: absolute;
  top: 75px;
  right: 18rem;
}

.normal-label-style {
  position: absolute;
  left: 26.4rem;
}

.low-label-style {
  position: absolute;
  top: 4.4rem;
  left: 18rem;
}

.date-month-calender-view {
  background: var(--secondary-color);
  padding: 10px 22px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  display: flex;
  justify-content: center;
  font-weight: 500;
}

.date-month-view-style {
  text-align: center;
  display: flex;
  background: #ededed;
  padding: 10px 16px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  justify-content: center;
  font-weight: 500;
}

.bg-secondary-color {
  background-color: var(--secondary-color);
}

.calender-view-rod-right {
  position: absolute;
  background: darkgrey;
  padding: 10px 3px;
  border-radius: 7px;
  top: -8px;
  /* left: 5px; */
  right: 7px;
  z-index: 2;
}

.calender-view-rod-left {
  position: absolute;
  background: darkgrey;
  padding: 10px 3px;
  border-radius: 7px;
  top: -8px;
  left: 7px;
  z-index: 2;
}

.calender-view-hole-right {
  position: absolute;
  background: white;
  padding: 5px;
  border-radius: 50%;
  top: 4px;
  /* left: 5px; */
  right: 5px;
  z-index: 1;
}

.calender-view-hole-left {
  position: absolute;
  background: white;
  padding: 5px;
  border-radius: 50%;
  top: 4px;
  left: 5px;
  z-index: 1;
}

.react-datepicker-popper {
  z-index: 3 !important;
}

.reset-password-color {
  color: var(--secondary-color);
  /* text-decoration: underline; */
}

.reset-form-styling {
  padding: 20px 12rem;
}

.reports-card-height {
  max-height: 715px;
  overflow-y: auto;
}

.appointment-dropdown .btn-primary:hover,
.appointment-dropdown .btn-primary,
.appointment-dropdown .btn-primary:focus {
  color: var(--secondary-color) !important;
  background-color: #fff !important;
  box-shadow: none;
  border-color: #fff !important;
  text-decoration: underline;
  display: flex;
  justify-content: end;
  align-items: end;
  margin-right: -14px;
}

.auth-content .submit-btn:disabled {
  cursor: no-drop;
}

/* .dashboard-parent-class .form-control:disabled {
  background-color: #ffffff !important;
}
.dashboard-parent-class input:disabled {
  background-color: #ffffff !important;
  cursor: default;
} */
.loader-space-style {
  margin-top: 10rem;
}

.report-truncate-style {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  max-width: 200px;
}

.highcharts-legend-item.highcharts-column-series.highcharts-color-undefined.highcharts-series-0 {
  display: none !important;
}

.input-search-control {
  border-right: 0px !important;
}

.dashboard-parent-class .react-datepicker__navigation--previous {
  left: 2px;
  top: -12px;
}

.dashboard-parent-class .react-datepicker__navigation--next {
  right: 2px;
  top: -13px;
}

/* .dashboard-parent-class .react-datepicker__month-wrapper {
  padding-bottom: 8px;
} */
.dashboard-parent-class .react-datepicker__month-text--selected {
  padding: 4px;
  border-radius: 0.3rem;
  background-color: #bad9f1;
  color: rgb(0, 0, 0);
}

.dashboard-parent-class .react-datepicker__month-text--selected:hover {
  background-color: #1d5d90;
}

.dashboard-parent-class .react-datepicker__month-text {
  padding: 4px;
}

.highcharts-legend-box {
  width: 0px !important;
}

.upcoming-button-css {
  font-size: 11px;
  padding: 5px;
}

.document-btn-style {
  margin-top: -10px;
}

.truncate-lab-test {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
}

/* .download-button-show-hide {
  display: block !important;
}
.pdf-button-show-hide {
  display: none !important;
} */


.react-international-phone-input-container .form-control {
  border: 1px solid #e2e5ed;
  height: calc(1.4em + 1.4rem + 2px);
  width: 100%;
  border-radius: 0 4px 4px 0 !important;
  font-size: 15px;
}

.react-international-phone-input-container .react-international-phone-country-selector button {
  border: 1px solid #e2e5ed;
  /*height: calc(1.4em + 1.4rem + 2px);*/
  height: 100%;
  padding: 0 10px;
}

.react-international-phone-input-container .selected-flag,
.react-international-phone-input-container .flag-dropdown.open .selected-flag {
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.react-international-phone-input-container .flag-dropdown.input-field-error {
  border: 1px solid var(--error-or-danger-color);
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-right: 0;
}

.react-international-phone-input-container:has(.input-field-error) {
  border: 1px solid var(--error-or-danger-color);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.modify-prescription-header.card-header {
  text-align: end;
  border: 1px solid #d7d7d7;
  border-bottom: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.modify-prescription-header.card-header button{
  text-align: end;
  border: 1px solid #d7d7d7;
  border-bottom: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.modify-prescription-card.card {
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}