@media (min-width: 1200px) {
  .container {
    max-width: 1390px;
  }
  .download-button-show-hide {
    display: none !important;
  }
  .pdf-file-show-hide {
    display: block !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1536px) {
  .specializatoin-fixed-height .col-lg-6 {
    width: 100% !important;
  }
  .download-button-show-hide {
    display: none !important;
  }
  .pdf-file-show-hide {
    display: block !important;
  }

  .upcoming-button-css {
    font-size: 8px;
    padding: 2px;
  }

  .pin-custom-input .input-group-text {
    padding: 0.9rem 0rem 0.9rem 0.4rem;
  }

  .header-button-style {
    padding-top: 10px;
  }

  .truncate {
    width: 320px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .truncate-profile {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .high-label-style {
    position: absolute;
    top: 75px;
    right: 11.5rem;
  }
  .normal-label-style {
    position: absolute;
    left: 19rem;
  }
  .low-label-style {
    position: absolute;
    top: 4.4rem;
    left: 12rem;
  }
}

@media screen and (max-width: 1440px) {
  .auth-wrapper .auth-content {
    width: 70%;
  }

  .download-button-show-hide {
    display: none !important;
  }
  .pdf-file-show-hide {
    display: block !important;
  }
}

@media screen and (max-width: 1200px) {
  .auth-wrapper .auth-content {
    width: 80%;
  }

  .download-button-show-hide {
    display: none !important;
  }
  .pdf-file-show-hide {
    display: block !important;
  }
  .high-label-style {
    right: 11.5rem;
  }
  .normal-label-style {
    left: 15rem;
  }
  .low-label-style {
    left: 12rem;
  }
}

@media only screen and (max-width: 1124px) {
  .nav.user-menu {
    width: 31%;
  }

  .download-button-show-hide {
    display: none !important;
  }
  .pdf-file-show-hide {
    display: block !important;
  }

  .date-selection-style {
    width: 75%;
  }

  .header-button-style button {
    margin: 3px 0px !important;
    padding: 4px 10px !important;
  }

  /* .header-button-style {
    padding-top: 30px;
  } */
  .tabs-style.active {
    padding: 10px 20px;
  }

  .tabs-style {
    padding: 10px 20px;
  }
}

@media only screen and (max-width: 992px) {
  .PageName
    :is(span h3, .image-side-text, .header-profile-section-mob, .user-img) {
    display: none !important;
  }
  .PageName {
    margin-top: 0px;
  }

  .download-button-show-hide {
    display: none !important;
  }
  .pdf-file-show-hide {
    display: block !important;
  }

  .lastlogin-style {
    display: none;
  }

  .nav.user-menu {
    width: 100%;
    text-align: right;
  }

  /* .header-button-style button {
    margin: 3px 5px !important;
  } */

  .header .header-left .logo,
  .sidebar .header-left .logo {
    margin: 0 0 10px 30px;
  }
}

@media only screen and (max-width: 768px) {
  .PageName :is(span h3, .image-side-text),
  .header .header-left {
    display: none;
  }

  .download-button-show-hide {
    display: block !important;
    text-align: center;
    margin-top: 5rem;
  }
  .pdf-file-show-hide {
    display: none !important;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
  }

  .PageName .header-profile-section-mob {
    display: block !important;
    margin: 0;
  }

  .prescription-d-flex-property {
    display: inline;
  }
  .search-input-prescription {
    margin-top: 10px;
    margin-left: 0px;
    width: 100%;
  }

  .PageName .user-img {
    display: flex !important;
    align-items: center;
    gap: 5px;
    margin-left: 0.6rem;
  }
  .user-img > img {
    height: 40px;
    object-fit: cover;
    width: 97px;
  }
  .upload-image-body {
    font-size: 15px;
  }
  .upload-image-aws-style {
    margin-left: -2rem;
  }
  .high-label-style {
    right: 1rem;
  }
  .normal-label-style {
    left: 9.5rem;
  }
  .low-label-style {
    left: 2.5rem;
  }
}

@media screen and (max-width: 767px) {
  .auth-wrapper .auth-content {
    width: 100%;
  }

  .download-button-show-hide {
    display: block !important;
    text-align: center;
    margin-top: 5rem;
  }
  .pdf-file-show-hide {
    display: none !important;
  }

  .custom-navigation-header {
    gap: 10px;
  }
}

@media only screen and (max-width: 575px) {
  .resposive-specialization-name {
    word-break: break-word;
  }

  .download-button-show-hide {
    display: block !important;
    text-align: center;
    margin-top: 5rem;
  }
  .pdf-file-show-hide {
    display: none !important;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 16px;
  }

  .user-img > img {
    height: 29px;
    object-fit: cover;
    width: 118px;
  }
  .upload-image-body {
    font-size: 10px;
    margin-top: 26px;
  }
  .upload-image-aws-style {
    margin-left: -2.6rem;
  }
  .slot-fix-height {
    min-height: 300px;
    height: 70px;
    overflow: auto;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(2, 1fr);
  }

  .dot-box {
    padding-top: 5px;
  }

  .book-an-appointment-parent-class .table > tbody > tr > td {
    white-space: inherit;
  }

  .search-input {
    width: 99% !important;
  }

  .header {
    padding: 0px 0 56px 0;
  }

  .header-button-style {
    right: 0;
    display: flex;
    justify-content: center;
    padding: 0px !important;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .header-button-style button {
    /* margin: 6px 2px !important; */
    margin-bottom: 6px !important;
    padding: 9px 9px !important;
    font-size: 11px;
  }

  #mobile_btn {
    padding: 0 12px;
    height: 40%;
    top: 0.7rem;
  }

  .nav.user-menu {
    margin-right: 12px !important;
    margin-top: 0.3rem;
  }

  /* .logout-text {
    margin: -20px -10px 0 0 !important;
  } */

  .card.fixed-height-card .card-header {
    padding: 10px 10px;
  }

  .card.fixed-height-card .card-body {
    padding: 10px 10px;
  }

  .day-cell {
    padding: 5px 7px;
  }

  .report-date-style {
    width: 90px;
  }

  .appointment-fixed-height.table-responsive {
    height: unset !important;
  }

  .date-selection-style {
    width: 100%;
  }
}

@media only screen and (min-width: 1745.45px) and (max-width: 1745.45px) {
  .pin-custom-input .input-group-text {
    padding: 0.9rem 0rem 0.8rem 0.4rem;
  }

  .download-button-show-hide {
    display: none !important;
  }
  .pdf-file-show-hide {
    display: block !important;
  }
  .high-label-style {
    position: absolute;
    top: 75px;
    right: 15rem;
  }
  .normal-label-style {
    position: absolute;
    left: 23rem;
  }
  .low-label-style {
    position: absolute;
    top: 4.4rem;
    left: 15rem;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1024px) {
  .header .header-left {
    display: none;
  }

  .download-button-show-hide {
    display: none !important;
  }
  .pdf-file-show-hide {
    display: block !important;
  }
  .header-button-style {
    padding-top: 0px;
  }
  .high-label-style {
    right: 2rem;
  }
  .prescription-d-flex-property {
    display: inline;
  }
  .search-input-prescription {
    margin-top: 10px;
    margin-left: 0px;
    width: 100%;
  }
  .normal-label-style {
    left: 10rem;
  }
  .low-label-style {
    left: 2.5rem;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1280px) {
  .high-label-style {
    right: 6.5rem;
  }

  .download-button-show-hide {
    display: none !important;
  }
  .pdf-file-show-hide {
    display: block !important;
  }
  .grid-container {
    /* overflow: auto; */
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
  }
  .prescription-d-flex-property {
    display: inline;
  }
  .search-input-prescription {
    margin-top: 10px;
    margin-left: 0px;
    width: 100%;
  }
  .normal-label-style {
    left: 15rem;
  }
  .low-label-style {
    left: 6.5rem;
  }
}

@media only screen and (min-width: 1097.14px) and (max-width: 1097.14px) {
  .high-label-style {
    right: 3.5rem;
  }

  .download-button-show-hide {
    display: none !important;
  }
  .pdf-file-show-hide {
    display: block !important;
  }
  .prescription-d-flex-property {
    display: inline;
  }
  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
  }

  .search-input-prescription {
    margin-top: 10px;
    margin-left: 0px;
    width: 100%;
  }
  .normal-label-style {
    left: 11rem;
  }
  .low-label-style {
    left: 4rem;
  }
}

@media only screen and (min-width: 960px) and (max-width: 960px) {
  .PageName :is(span h3, .image-side-text),
  .header .header-left {
    display: none;
  }

  .download-button-show-hide {
    display: block !important;
    text-align: center;
    margin-top: 5rem;
  }
  .pdf-file-show-hide {
    display: none !important;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
  }

  .prescription-d-flex-property {
    display: inline;
  }
  .search-input-prescription {
    margin-top: 10px;
    margin-left: 0px;
    width: 100%;
  }

  .PageName .header-profile-section-mob {
    display: block !important;
    margin: 0;
  }

  .PageName .user-img {
    display: flex !important;
    align-items: center;
    gap: 5px;
    margin-left: 0.6rem;
  }
  .user-img > img {
    height: 40px;
    object-fit: cover;
    width: 87px;
  }
  .upload-image-body {
    font-size: 15px;
  }
  .upload-image-aws-style {
    margin-left: -1.8rem;
  }
  .high-label-style {
    right: 5rem;
  }
  .normal-label-style {
    left: 13rem;
  }
  .low-label-style {
    left: 5.5rem;
  }
}

@media only screen and (min-width: 640px) and (max-width: 640px) {
  .user-img > img {
    height: 32px;
    object-fit: cover;
    width: 100px;
  }
  .download-button-show-hide {
    display: block !important;
    text-align: center;
    margin-top: 5rem;
  }
  .pdf-file-show-hide {
    display: none !important;
  }
  .upload-image-body {
    font-size: 12px;
  }
  .prescription-d-flex-property {
    display: flex;
  }
  .search-input-prescription {
    margin-top: 10px;
    margin-left: 10px;
    width: 100%;
  }
  .upload-image-aws-style {
    margin-left: -2.4rem;
  }
  .high-label-style {
    right: 7rem;
  }
  .normal-label-style {
    left: 15.5rem;
  }
  .low-label-style {
    left: 7.5rem;
  }
}

@media only screen and (min-width: 375px) and (max-width: 425px) {
  .user-img > img {
    height: 30px;
    object-fit: cover;
    width: 135px;
  }

  .download-button-show-hide {
    display: block !important;
    text-align: center;
    margin-top: 5rem;
  }
  .pdf-file-show-hide {
    display: none !important;
  }

  .prescription-d-flex-property {
    display: flex;
  }
  .search-input-prescription {
    margin-top: 10px;
    margin-left: 10px;
    width: 100%;
  }
  .upload-image-body {
    font-size: 9px;
    margin-top: 22px;
  }
  .upload-image-aws-style {
    margin-left: -2.9rem;
  }
  .high-label-style {
    right: 0rem;
  }
  .normal-label-style {
    left: 7.5rem;
  }
  .low-label-style {
    left: 0rem;
  }
}
