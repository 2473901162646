.App {
  text-align: center;
}

::-webkit-scrollbar {
  width: 7px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: rgb(255, 255, 255);
}

::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 25px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--primary-color);
}